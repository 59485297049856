import {Modal} from 'react-bootstrap'
import {Button} from '../atoms/Button.js'

export const DeleteCampaignModal = ({show, onHide, onDelete}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header>Delete Campaign</Modal.Header>
      <Modal.Body className='d-flex flex-column align-items-center'>
        <p
          className='d-text__placement--center'
          style={{padding: '1rem'}}
        >
          This action is permanent. You cannot retrieve the campaign again.
        </p>

        <Button
          children={'Delete campaign permanently'}
          onClick={onDelete}
        />
      </Modal.Body>
    </Modal>
  )
}
