import {useRecoilState} from 'recoil'
import {campaignBuilderAtom} from '../../states/campaigns.js'

export const useCampaignAtom = () => {
  const [campaignAtom, setCampaignAtom] = useRecoilState(campaignBuilderAtom)

  const setCampaignAtomKey = (key, value) => {
    setCampaignAtom((prevState) => ({...prevState, [key]: value}))
  }

  const campaignAtomFromResponse = (campaign) => {
    return {
      campaignName: campaign.campaign_name,
      campaignDescription: campaign.campaign_description,
      campaignSortingInstructions: campaign.campaign_sorting_instructions,
      maxOutreachMessages: campaign.max_people_outreach,
      maxOutreachMessagesPerDay: campaign.max_people_outreach_per_day,
      maxOutreachPeoplePerCompany: campaign.max_people_outreach_per_company,
      steps: campaign.steps.map((step) => ({
        index: step.index,
        messageInstructionsId: step.message_instructions_id,
        waitDays: step.wait_days
      })),
      peopleInAudience: campaign.people_in_audience,
      companiesInAudience: campaign.companies_in_audience,
      audienceId: campaign.audience_id,
      campaignId: campaign.campaign_id,
      campaignReviewReason: campaign.campaign_review_reason,
      campaignStatus: campaign.campaign_status,
      companiesFetched: campaign.companies_fetched,
      createdAt: campaign.created_at,
      messagesGenerated: campaign.messages_generated,
      peopleFetched: campaign.people_fetched,
      statistics: campaign?.statistics
        ? {
            outreachTrackingMetaData: {
              companyStatusAggregates: {
                awaitingOutreach:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.company_status_aggregates?.awaiting_outreach || 0,
                awaitingPeople:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.company_status_aggregates?.awaiting_people || 0,
                receivedBounce:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.company_status_aggregates?.received_bounce || 0,
                receivedNoResponse:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.company_status_aggregates?.received_no_response || 0,
                receivedResponseFromRecipient:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.company_status_aggregates?.received_response_from_recipient || 0,
                sent:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.company_status_aggregates?.sent || 0,
                skipped:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.company_status_aggregates?.skipped || 0
              },
              personOutreachAggregates:
                campaign?.statistics?.outreach_tracking_meta_data?.person_outreach_aggregates?.map(
                  (aggregate) => ({
                    countPeople: aggregate?.count_people || 0,
                    messagesSent: aggregate?.messages_sent || 0,
                    skipReason: aggregate?.skip_reason || '',
                    status: aggregate?.status || ''
                  })
                ) || [],
              personStatusAggregates: {
                awaitingMessage:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.person_status_aggregates?.awaiting_message || 0,
                awaitingOutreach:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.person_status_aggregates?.awaiting_outreach || 0,
                receivedBounce:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.person_status_aggregates?.received_bounce || 0,
                receivedNoResponse:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.person_status_aggregates?.received_no_response || 0,
                receivedResponseFromRecipient:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.person_status_aggregates?.received_response_from_recipient || 0,
                sent:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.person_status_aggregates?.sent || 0,
                skipped:
                  campaign?.statistics?.outreach_tracking_meta_data
                    ?.person_status_aggregates?.skipped || 0
              }
            }
          }
        : {},
      updatedAt: campaign.updated_at,
      userId: campaign.user_id
    }
  }

  const campaignStepsToResponse = (steps) => {
    return steps?.map((step) => ({
      index: step.index,
      message_instructions_id: step.messageInstructionsId,
      wait_days: step.waitDays
    }))
  }

  return {
    campaignAtom,
    setCampaignAtom,
    setCampaignAtomKey,
    campaignAtomFromResponse,
    campaignStepsToResponse
  }
}
