import {Sidebar} from '../organisms/Sidebar.js'
import {Row, Col} from 'react-bootstrap'
import {BaseTemplate} from './BaseTemplate.js'

export const SidebarTemplate = ({children}) => {
  return (
    <BaseTemplate>
      <Sidebar>
        <Row
          className='d-flex justify-content-center'
          style={{padding: 0, margin: 0}}
        >
          <Col
            md={12}
            lg={11}
            xl={10}
            xxl={9}
            className='d-flex flex-column align-items-stretch align-content-center'
          >
            {children}
          </Col>
        </Row>
      </Sidebar>
    </BaseTemplate>
  )
}
