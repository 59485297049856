import {Tooltip, OverlayTrigger} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'

export const CustomButton = ({
  label,
  iconName,
  onClick,
  isLoading,
  isDisabled,
  disabledText,
  style
}) => {
  const buttonDisabled = isDisabled || isLoading
  const tooltipOverlay = <Tooltip id='button-tooltip'>{disabledText}</Tooltip>

  const handleClick = (e) => {
    if (!buttonDisabled && onClick) {
      onClick(e)
    }
  }

  const dynamicClasses = [
    'transition-medium',
    'd-flex',
    'align-items-center',
    buttonDisabled ? 'text-muted' : 'color--accent clickable'
  ].join(' ')

  const dynamicStyles = {
    border: 'solid 1px transparent',
    padding: '0.35rem',
    borderRadius: '0.5rem',
    width: 'fit-content',
    cursor: 'pointer',
    opacity: buttonDisabled ? 0.6 : 1,
    ...style
  }

  const buttonContent = (
    <div
      onClick={handleClick}
      style={dynamicStyles}
      className={dynamicClasses}
      role='button'
      aria-disabled={buttonDisabled}
      tabIndex={buttonDisabled ? -1 : 0}
    >
      {label}
      {isLoading ? (
        <Spinner style={{margin: '0 0.25rem 0 0.5rem'}} />
      ) : (
        <span
          className='material-symbols-outlined'
          style={{marginLeft: '0.25rem'}}
        >
          {iconName}
        </span>
      )}
    </div>
  )

  if (isDisabled && disabledText) {
    return (
      <OverlayTrigger
        overlay={tooltipOverlay}
        placement='top'
      >
        {buttonContent}
      </OverlayTrigger>
    )
  } else {
    return buttonContent
  }
}
