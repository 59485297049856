import {useState, useEffect} from 'react'
import {Fade} from 'react-bootstrap'

export const RotatingHeaderWrapper = ({
  firstPart,
  rotatingWords,
  lastPart,
  tag: Tag = 'h1',
  className = 'd-text__font--subheading color--p-light',
  style = {}
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentWord, setCurrentWord] = useState(rotatingWords[0])
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
    const interval = setInterval(() => {
      setShow(false)
      setTimeout(() => {
        const nextIndex = (currentIndex + 1) % rotatingWords.length
        setCurrentIndex(nextIndex)
        setCurrentWord(rotatingWords[nextIndex])
        setShow(true)
      }, 500)
    }, 3000)

    return () => clearInterval(interval)
  }, [currentIndex, rotatingWords])

  return (
    <Tag
      className={className}
      style={{whiteSpace: 'nowrap', ...style}}
    >
      {firstPart}
      <Fade
        in={show}
        timeout={1000}
      >
        <span style={{position: 'relative', color: 'var(--accent)'}}>
          {currentWord}
        </span>
      </Fade>
      {lastPart}
    </Tag>
  )
}
