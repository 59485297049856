import {useState, useEffect, useRef} from 'react'

export const EditableField = ({value, onChange, onBlur, style, inputStyle}) => {
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  const handleBlur = () => {
    setIsEditing(false)
    if (onBlur) onBlur()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.target.blur()
    }
  }

  return isEditing ? (
    <input
      type='text'
      value={value}
      onChange={(e) => onChange(e.target.value)}
      ref={inputRef}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      style={{...inputStyle}}
      className='form-control'
    />
  ) : (
    <span
      onClick={() => setIsEditing(true)}
      style={{cursor: 'pointer', ...style}}
    >
      {value}
    </span>
  )
}
