//Atoms
import {Stack, Overlay, Tooltip, Button} from 'react-bootstrap'
import {useRef, useState} from 'react'

import {Spinner} from '../atoms/Spinner.js'

export const ActionWithConfirmation = ({
  callback,
  disabled,
  placement = 'right',
  style = {},
  isPending = false
}) => {
  const [showDeleteConfirmOverlay, setShowDeleteConfirmOverlay] = useState(false)
  const deleteOverlayRef = useRef(null)

  return (
    <>
      {isPending ? (
        <Spinner animation='grow' />
      ) : (
        <span
          className={
            disabled
              ? 'material-symbols-outlined'
              : 'material-symbols-outlined color--p-dark clickable transition-fast t-scale--small t-color--accent'
          }
          style={{opacity: disabled ? 0.5 : 1, ...style}}
          onClick={disabled ? undefined : () => setShowDeleteConfirmOverlay(true)}
          ref={deleteOverlayRef}
        >
          {'delete'}
        </span>
      )}
      <Overlay
        target={deleteOverlayRef.current}
        show={showDeleteConfirmOverlay}
        placement={placement}
        rootClose
        onHide={() => setShowDeleteConfirmOverlay(false)}
      >
        {(props) => (
          <Tooltip
            id='delete-confirmation-tooltip'
            {...props}
          >
            <Stack
              direction='vertical'
              gap={2}
            >
              {'Are you sure?'}
              <Button
                size='sm'
                variant='outline-secondary'
                onClick={() => {
                  callback()
                  setShowDeleteConfirmOverlay(false)
                }}
              >
                Yes!
              </Button>
            </Stack>
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}
