import {useState, useRef, useMemo} from 'react'
import {
  Collapse,
  Row,
  Form,
  InputGroup,
  FloatingLabel,
  Stack,
  ListGroup,
  Col,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Container,
  Image
} from 'react-bootstrap'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent
} from '@mui/lab'

import {Spinner} from '../atoms/Spinner.js'
import {Button} from '../atoms/Button.js'

import {useAutosizeTextArea} from '../../utils/hooks.js'
import {MatchesFoundRounder} from '../../utils/calculations.js'
import {InputWrapper, isTruthy, getColorBasedOnScore} from '../../utils/hooks.js'

export const ConversationTimeline = ({
  conversationState,
  setConversationState,
  stepsAreValid,
  messageInstructions,
  messageInstructionsIsLoading,
  messageInstructionsIsFetching
}) => {
  const [hoverIndex, setHoverIndex] = useState(null)

  const messageInstructionsMap = useMemo(() => {
    const map = {}
    messageInstructions?.forEach((mi) => {
      map[mi.message_instructions_id] = mi
    })
    return map
  }, [messageInstructions])

  const [initialMessages, followUpMessages] = useMemo(() => {
    const initials = []
    const followUps = []
    messageInstructions?.forEach((mi) => {
      if (mi.outreach_stage === 'initial_message') {
        initials.push(mi)
      } else if (mi.outreach_stage === 'follow_up_message') {
        followUps.push(mi)
      }
    })
    return [initials, followUps]
  }, [messageInstructions])

  const handleAddTimelineItem = (messageInstructionsId) => {
    const newItem = {
      index: conversationState?.length,
      messageInstructionsId: messageInstructionsId,
      waitDays: 1
    }
    setConversationState([...conversationState, newItem])
  }
  const handleRemoveTimelineItem = (indexToRemove) => {
    const newConversationState = conversationState
      .filter((_, index) => index !== indexToRemove)
      .map((item, index) => ({...item, index}))
    setConversationState(newConversationState)
  }

  return (
    <Timeline style={{margin: 0}}>
      {conversationState?.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent sx={{m: 'auto 0'}}>
            <Stack direction='vertical'>
              <p
                className='d-text__font--body color--p-dark'
                style={{padding: 0, margin: 0}}
              >{`Message ${index + 1}`}</p>
              <Collapse in={isTruthy(item?.messageInstructionsId)}>
                <small className='d-text__font--body color--p-dark'>
                  {messageInstructionsMap[item?.messageInstructionsId]?.intention}
                </small>
              </Collapse>
            </Stack>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector
              style={
                index === 0
                  ? {backgroundColor: 'transparent', width: '1.5px'}
                  : {width: '1.5px'}
              }
            />
            <TimelineDot
              variant='outlined'
              style={{
                borderColor: '#BDBDBD'
              }}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
              className={index !== 0 ? 'transition-fast clickable t-scale--small' : ''}
            >
              {index === 0 ? (
                <span
                  className='material-symbols-outlined'
                  style={{color: '#BDBDBD'}}
                >
                  {'send'}
                </span>
              ) : hoverIndex === index ? (
                <span
                  onClick={() => handleRemoveTimelineItem(index)}
                  className='material-symbols-outlined clickable'
                  style={{color: '#BDBDBD'}}
                >
                  {'remove'}
                </span>
              ) : (
                <span
                  onClick={() => handleRemoveTimelineItem(index)}
                  className='material-symbols-outlined clickable'
                  style={{color: '#BDBDBD'}}
                >
                  {'prompt_suggestion'}
                </span>
              )}
            </TimelineDot>
            <TimelineConnector
              style={
                item?.index === 3 || !stepsAreValid
                  ? {backgroundColor: 'transparent', width: '1.5px'}
                  : {width: '1.5px'}
              }
            />
          </TimelineSeparator>
          <TimelineContent>
            <Stack
              direction='horizontal'
              gap={2}
            >
              <InputGroup>
                <InputWrapper
                  isLoading={
                    messageInstructionsIsFetching || messageInstructionsIsLoading
                  }
                >
                  <FloatingLabel
                    label='Message Instructions'
                    className='d-text__font--heading'
                  >
                    <Form.Select
                      value={item.messageInstructionsId || ''}
                      onChange={(e) => {
                        const newSteps = conversationState?.map((step, sIndex) => {
                          if (sIndex === index) {
                            return {
                              ...step,
                              messageInstructionsId: e.target.value
                            }
                          }
                          return step
                        })
                        setConversationState(newSteps)
                      }}
                    >
                      <option
                        key={''}
                        value={''}
                      >
                        Select Message Instructions
                      </option>
                      {(index === 0 ? initialMessages : followUpMessages)?.map((mi) => (
                        <option
                          key={mi.message_instructions_id}
                          value={mi.message_instructions_id}
                        >
                          {mi.label}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </InputWrapper>
              </InputGroup>
              {index > 0 && (
                <InputGroup style={{width: 'auto'}}>
                  <FloatingLabel
                    label='Days To Wait'
                    className='d-text__font--heading'
                  >
                    <FormControl
                      type='number'
                      min={1}
                      value={item.waitDays}
                      onChange={(e) => {
                        const newSteps = conversationState?.map((step, sIndex) => {
                          if (sIndex === index) {
                            return {...step, waitDays: Number(e.target.value)}
                          }
                          return step
                        })
                        setConversationState(newSteps)
                      }}
                      placeholder=''
                    />
                  </FloatingLabel>
                </InputGroup>
              )}
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ))}
      <Collapse in={conversationState?.length !== 4 && stepsAreValid}>
        <div style={{width: '100%'}}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                variant='outlined'
                style={{
                  borderColor: '#BDBDBD'
                }}
                onClick={() =>
                  handleAddTimelineItem(
                    followUpMessages?.[0]?.message_instructions_id || undefined
                  )
                }
                className='clickable transition-fast clickable t-scale--small'
              >
                <span
                  className='material-symbols-outlined'
                  style={{color: '#BDBDBD'}}
                >
                  {'add'}
                </span>
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent />
          </TimelineItem>
        </div>
      </Collapse>
    </Timeline>
  )
}

export const CadenceDetails = ({
  campaignAtom,
  setCampaignAtomKey,
  stepsAreValid,
  messageInstructions,
  messageInstructionsIsLoading,
  messageInstructionsIsFetching
}) => {
  return (
    <Container fluid>
      <ConversationTimeline
        conversationState={campaignAtom.steps}
        setConversationState={(e) => setCampaignAtomKey('steps', e)}
        messageInstructions={messageInstructions}
        messageInstructionsIsLoading={messageInstructionsIsLoading}
        messageInstructionsIsFetching={messageInstructionsIsFetching}
        stepsAreValid={stepsAreValid}
      />
    </Container>
  )
}

export const AudienceDetails = ({
  audiences,
  audiencesIsFetching,
  audiencesIsLoading,
  audienceAlreadyInUse,
  audienceCompaniesError,
  audiencePeopleError,
  audienceIsValid,
  campaignAtom,
  companiesSearchResultsIsFetching,
  companiesSearchResultsIsLoading,
  leadsRankResultsFetching,
  leadsRankResultsLoading,
  peopleSearchResults,
  peopleSearchResultsIsFetching,
  peopleSearchResultsIsLoading,
  rankedPeopleSearchResults,
  selectedAudience,
  setCampaignAtomKey,
  sortingInstructionsIsDebouncing
}) => {
  const resultsAreLoading =
    companiesSearchResultsIsFetching ||
    companiesSearchResultsIsLoading ||
    peopleSearchResultsIsFetching ||
    peopleSearchResultsIsLoading

  const showAudienceStatistics = isTruthy(selectedAudience)
  const showInstructionsInput = showAudienceStatistics && audienceIsValid
  const resultsToDisplay =
    rankedPeopleSearchResults?.pages?.[0]?.search_results ||
    peopleSearchResults?.pages?.[0]?.search_results

  const showResults = showInstructionsInput && isTruthy(resultsToDisplay)

  return (
    <Container fluid>
      <Form className='d-form'>
        <Form.Group as={Row}>
          <InputGroup>
            <InputWrapper
              isLoading={audiencesIsFetching || audiencesIsLoading}
              tooltipMessage={
                audienceAlreadyInUse
                  ? 'Audience already in use by another campaign'
                  : undefined
              }
              tooltipId={'audience-dropdown-tooltip'}
            >
              <FloatingLabel label='Audience'>
                <Form.Select
                  value={campaignAtom?.audienceId || ''}
                  className={audienceAlreadyInUse ? 'invalidInput' : ''}
                  onChange={(e) => {
                    setCampaignAtomKey('audienceId', e?.target?.value)
                  }}
                >
                  <option
                    key={''}
                    value={''}
                  >
                    Select an Audience
                  </option>
                  {audiences?.map((audience) => (
                    <option
                      key={audience?.audience_id}
                      value={audience?.audience_id}
                    >
                      {audience?.label}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </InputWrapper>
          </InputGroup>
        </Form.Group>

        <Collapse in={showAudienceStatistics}>
          <div>
            <Form.Group as={Row}>
              <InputWrapper
                tooltipMessage={audienceCompaniesError}
                tooltipId={'companies-in-audience-tooltip'}
              >
                <InputGroup
                  as={Col}
                  style={{marginRight: '0.75rem'}}
                >
                  <InputGroup.Text
                    className={isTruthy(audienceCompaniesError) ? 'invalidInput' : ''}
                  >
                    {companiesSearchResultsIsFetching ||
                    companiesSearchResultsIsLoading ? (
                      <Spinner />
                    ) : (
                      <span className='material-symbols-outlined color--p-dark'>
                        {'source_environment'}
                      </span>
                    )}
                  </InputGroup.Text>
                  <Form.Control
                    type='text'
                    value={`~ ${MatchesFoundRounder(
                      selectedAudience?.companies_in_audience
                    )} companies`}
                    name='companiesInAudience'
                    className={isTruthy(audienceCompaniesError) ? 'invalidInput' : ''}
                    readOnly
                  />
                </InputGroup>
              </InputWrapper>

              <InputWrapper
                tooltipMessage={audiencePeopleError}
                tooltipId={'companies-in-audience-tooltip'}
              >
                <InputGroup as={Col}>
                  <InputGroup.Text
                    className={isTruthy(audiencePeopleError) ? 'invalidInput' : ''}
                  >
                    {peopleSearchResultsIsFetching || peopleSearchResultsIsLoading ? (
                      <Spinner />
                    ) : (
                      <span className='material-symbols-outlined color--p-dark'>
                        {'groups'}
                      </span>
                    )}
                  </InputGroup.Text>
                  <Form.Control
                    type='text'
                    className={isTruthy(audiencePeopleError) ? 'invalidInput' : ''}
                    value={`~ ${MatchesFoundRounder(
                      selectedAudience?.people_in_audience
                    )} people`}
                    name='peopleInAudience'
                    readOnly
                  />
                </InputGroup>
              </InputWrapper>
            </Form.Group>
          </div>
        </Collapse>

        <Collapse in={showInstructionsInput}>
          <div>
            <Form.Group as={Row}>
              <Form.Group
                as={Col}
                xs={'auto'}
                style={{marginRight: '2rem'}}
              >
                <Form.Label as={Row}>Ranking Instructions</Form.Label>
                <Form.Text
                  muted
                  as={Row}
                >
                  Rank people based on...
                </Form.Text>
              </Form.Group>

              <InputGroup as={Col}>
                <Form.Control
                  type='text'
                  placeholder='how likely they are a decision maker'
                  name='campaignSortingInstructions'
                  style={{borderRight: 'none'}}
                  value={campaignAtom?.campaignSortingInstructions || ''}
                  onChange={(e) => {
                    setCampaignAtomKey('campaignSortingInstructions', e?.target?.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                />
                <InputGroup.Text style={{borderLeft: 'none'}}>
                  {sortingInstructionsIsDebouncing &&
                  isTruthy(campaignAtom?.campaignSortingInstructions) ? (
                    <Spinner />
                  ) : (
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='ai-sorting-help-tooltip'>
                          Add an instruction for the AI to help you sort the people you
                          want to reach out to. Ex. “How likely they are to make
                          commercial decisions.”
                        </Tooltip>
                      }
                    >
                      <span className='material-symbols-outlined color--s-dark'>
                        {'help'}
                      </span>
                    </OverlayTrigger>
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </div>
        </Collapse>

        <Collapse in={showResults}>
          <div>
            {resultsAreLoading ? (
              <Spinner />
            ) : (
              <h6
                style={{textAlign: 'center', paddingBottom: 0}}
                className='d-text__font--heading color--p-dark'
              >
                Sorting Results Example
              </h6>
            )}

            <div
              style={{
                overflowY: 'auto',
                maxHeight: '30rem'
              }}
            >
              <ListGroup
                variant='flush'
                style={{
                  border: 'var(--bs-border-width) solid var(--bs-border-color)',
                  borderRadius: '0.5rem'
                }}
              >
                {resultsToDisplay?.map((result) => (
                  <ListGroup.Item
                    key={result?.person?.id}
                    className='d-flex align-items-center justify-content-between'
                    style={{height: 'auto', width: '100%'}}
                  >
                    <Stack
                      direction='horizontal'
                      gap={3}
                    >
                      {result?.person?.photo ? (
                        <Image
                          src={result?.person?.photo}
                          onError={(e) => {
                            e.target.onerror = null
                            e.target.style.display = 'none'
                          }}
                          alt={'Employee'}
                          fluid
                          rounded
                          style={{width: '3rem'}}
                        />
                      ) : null}
                      {!result?.person?.photo && (
                        <span
                          className='material-symbols-outlined color--p-dark'
                          style={{fontSize: '3rem'}}
                        >
                          {'groups'}
                        </span>
                      )}
                      <Stack direction='vertical'>
                        <h6
                          style={{margin: 0}}
                        >{`${result?.person?.first_name} ${result?.person?.last_name}`}</h6>
                        <small>{result?.role_title || result?.person?.headline}</small>
                      </Stack>
                    </Stack>

                    {leadsRankResultsFetching || leadsRankResultsLoading ? (
                      <Spinner
                        size='sm'
                        animation='grow'
                      />
                    ) : (
                      <span
                        className='material-symbols-outlined'
                        style={{color: getColorBasedOnScore(result?.score)}}
                      >
                        {'arrow_upward'}
                      </span>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </div>
        </Collapse>
      </Form>
    </Container>
  )
}

export const CampaignDetails = ({
  campaignAtom,
  setCampaignAtomKey,
  handlePostCampaign,
  postCampaignIsPending,
  allInputIsCorrect
}) => {
  const descriptionRef = useRef(null)
  useAutosizeTextArea(descriptionRef, campaignAtom?.campaignDescription)
  const [autoPlay, setAutoPlay] = useState(false)

  return (
    <Container fluid>
      <Form className='d-form'>
        <Form.Group as={Row}>
          <InputGroup>
            <FloatingLabel label='Campaign Name'>
              <Form.Control
                type='text'
                value={campaignAtom?.campaignName || ''}
                name='campaignName'
                onChange={(e) => {
                  setCampaignAtomKey('campaignName', e?.target?.value)
                }}
                placeholder=''
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Row}>
          <InputGroup>
            <FloatingLabel label='Campaign Description'>
              <Form.Control
                type='textarea'
                ref={descriptionRef}
                name='campaignDescription'
                value={campaignAtom?.campaignDescription || ''}
                onChange={(e) => {
                  setCampaignAtomKey('campaignDescription', e?.target?.value)
                }}
                placeholder=''
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </Form.Group>

        <Form.Group
          as={Row}
          className='d-flex align-items-center justify-content-center'
        >
          <Col
            xs='auto'
            style={{marginRight: '1rem'}}
          >
            <Form.Check
              reverse
              type='switch'
              label='Immediately start campaign'
              checked={autoPlay}
              onChange={(e) => {
                setAutoPlay(e.target.checked)
              }}
            />
          </Col>
          <Col xs='auto'>
            <Button
              children={'Save Campaign'}
              disabled={!allInputIsCorrect}
              disabledText={'Please review your campaign input'}
              onClick={() => {
                handlePostCampaign(
                  autoPlay ? {status: 'in_progress'} : {status: 'paused'}
                )
              }}
              isLoading={postCampaignIsPending}
            />
          </Col>
        </Form.Group>
      </Form>
    </Container>
  )
}

export const SettingsDetails = ({
  campaignAtom,
  setCampaignAtomKey,
  maxPeopleOutreachError,
  maxPeopleOutreachPerDayError,
  maxPeopleOutreachPerCompanyError,
  outreachInputIsValid,
  companiesToReach,
  peopleToReach,
  companiesToReachError,
  peopleToReachError,
  peopleInAudience,
  companiesInAudience
}) => {
  return (
    <Container fluid>
      <Row style={{margin: '0 0 1rem 0'}}>
        <InputWrapper
          tooltipMessage={maxPeopleOutreachPerCompanyError}
          tooltipId={'max-people-outreach-per-company-tooltip'}
        >
          <InputGroup
            style={{width: 'auto'}}
            as={Col}
          >
            <InputGroup.Text
              className={!!maxPeopleOutreachPerCompanyError ? 'invalidInput' : ''}
            >
              <span className='material-symbols-outlined color--p-dark'>
                {'source_environment'}
              </span>
            </InputGroup.Text>
            <FloatingLabel label='People Per Company'>
              <FormControl
                type='number'
                min={1}
                max={10000}
                className={!!maxPeopleOutreachPerCompanyError ? 'invalidInput' : ''}
                value={campaignAtom?.maxOutreachPeoplePerCompany || ''}
                onChange={(e) => {
                  const newValue = Number(e.target.value)
                  if (newValue > 10000) {
                    setCampaignAtomKey('maxOutreachPeoplePerCompany', 10000)
                  } else {
                    setCampaignAtomKey('maxOutreachPeoplePerCompany', newValue)
                  }
                }}
                placeholder=''
              />
            </FloatingLabel>
          </InputGroup>
        </InputWrapper>

        <InputWrapper
          tooltipMessage={maxPeopleOutreachPerDayError}
          tooltipId={'max-people-outreach-per-day-tooltip'}
        >
          <InputGroup
            style={{width: 'auto'}}
            as={Col}
          >
            <InputGroup.Text
              className={!!maxPeopleOutreachPerDayError ? 'invalidInput' : ''}
            >
              <span className='material-symbols-outlined color--s-dark'>
                {'event_available'}
              </span>
            </InputGroup.Text>
            <FloatingLabel label='Messages Per Day'>
              <FormControl
                type='number'
                min={1}
                max={50}
                value={campaignAtom?.maxOutreachMessagesPerDay || ''}
                className={!!maxPeopleOutreachPerDayError ? 'invalidInput' : ''}
                onChange={(e) => {
                  const newValue = Number(e.target.value)
                  if (newValue > 50) {
                    setCampaignAtomKey('maxOutreachMessagesPerDay', 50)
                  } else {
                    setCampaignAtomKey('maxOutreachMessagesPerDay', newValue)
                  }
                }}
                placeholder=''
              />
            </FloatingLabel>
          </InputGroup>
        </InputWrapper>

        <InputWrapper
          tooltipMessage={maxPeopleOutreachError}
          tooltipId={'max-people-outreach-tooltip'}
        >
          <InputGroup
            style={{width: 'auto'}}
            as={Col}
          >
            <InputGroup.Text className={!!maxPeopleOutreachError ? 'invalidInput' : ''}>
              <span className='material-symbols-outlined color--s-dark'>
                {'checklist'}
              </span>
            </InputGroup.Text>
            <FloatingLabel label='Messages Limit'>
              <FormControl
                type='number'
                min={1}
                max={40000}
                className={!!maxPeopleOutreachError ? 'invalidInput' : ''}
                value={campaignAtom?.maxOutreachMessages || ''}
                onChange={(e) => {
                  const newValue = Number(e.target.value)
                  if (newValue > 40000) {
                    setCampaignAtomKey('maxOutreachMessages', 40000)
                  } else {
                    setCampaignAtomKey('maxOutreachMessages', newValue)
                  }
                }}
                placeholder=''
              />
            </FloatingLabel>
          </InputGroup>
        </InputWrapper>
      </Row>

      <Collapse in={outreachInputIsValid}>
        <div style={{width: '100%'}}>
          <h6
            style={{textAlign: 'center', paddingBottom: 0}}
            className='d-text__font--heading color--p-dark'
          >
            Campaign Outcome Estimations
          </h6>

          <Row style={{margin: '0.5rem 0 0.5rem 0'}}>
            <InputWrapper
              tooltipMessage={companiesToReachError}
              tooltipId={'companies-to-reach-tooltip'}
            >
              <InputGroup
                style={{width: 'auto'}}
                as={Col}
              >
                <InputGroup.Text
                  className={!!companiesToReachError ? 'invalidInput' : ''}
                >
                  <>
                    <span className='material-symbols-outlined color--p-dark'>
                      {'source_environment'}
                    </span>

                    <span
                      className='material-symbols-outlined color--p-dark'
                      style={{
                        fontSize: '1rem',
                        marginLeft: '-0.5rem',
                        marginRight: '-0.5rem',
                        marginBottom: '1.5rem'
                      }}
                    >
                      {'chat_bubble'}
                    </span>
                  </>
                </InputGroup.Text>
                <FloatingLabel label='Estimated Companies Reach'>
                  <FormControl
                    type='text'
                    style={{borderRight: 'none'}}
                    className={!!companiesToReachError ? 'invalidInput' : ''}
                    value={`~${MatchesFoundRounder(
                      companiesToReach
                    )} out of ~${MatchesFoundRounder(companiesInAudience)}`}
                    placeholder=''
                    readOnly
                  />
                </FloatingLabel>
                <InputGroup.Text
                  style={{borderLeft: 'none'}}
                  className={!!companiesToReachError ? 'invalidInput' : ''}
                >
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='companies-estimate-help-tooltip'>
                        An estimation on how many companies you will reach with your
                        current settings. Adjust your outreach settings to reach the
                        desired outcome.
                      </Tooltip>
                    }
                  >
                    <span className='material-symbols-outlined color--s-dark'>
                      {'help'}
                    </span>
                  </OverlayTrigger>
                </InputGroup.Text>
              </InputGroup>
            </InputWrapper>

            <InputWrapper
              tooltipMessage={peopleToReachError}
              tooltipId={'people-to-reach-tooltip'}
            >
              <InputGroup
                style={{width: 'auto'}}
                as={Col}
              >
                <InputGroup.Text className={!!peopleToReachError ? 'invalidInput' : ''}>
                  <>
                    <span
                      className='material-symbols-outlined color--p-dark'
                      style={{fontSize: '2rem'}}
                    >
                      {'groups'}
                    </span>
                    <span
                      className='material-symbols-outlined color--p-dark'
                      style={{
                        fontSize: '1rem',
                        marginLeft: '-0.5rem',
                        marginRight: '-0.5rem',
                        marginBottom: '1.5rem'
                      }}
                    >
                      {'chat_bubble'}
                    </span>
                  </>
                </InputGroup.Text>
                <FloatingLabel label='Estimated People Reach'>
                  <FormControl
                    type='text'
                    style={{borderRight: 'none'}}
                    className={!!peopleToReachError ? 'invalidInput' : ''}
                    value={`~${MatchesFoundRounder(
                      peopleToReach
                    )} out of ~${MatchesFoundRounder(peopleInAudience)}`}
                    placeholder=''
                    readOnly
                  />
                </FloatingLabel>
                <InputGroup.Text
                  style={{borderLeft: 'none'}}
                  className={!!peopleToReachError ? 'invalidInput' : ''}
                >
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='people-estimate-help-tooltip'>
                        An estimation on how many people you will reach in total with
                        your current settings. Adjust your outreach settings to reach
                        the desired outcome.
                      </Tooltip>
                    }
                  >
                    <span className='material-symbols-outlined color--s-dark'>
                      {'help'}
                    </span>
                  </OverlayTrigger>
                </InputGroup.Text>
              </InputGroup>
            </InputWrapper>
          </Row>
        </div>
      </Collapse>
    </Container>
  )
}
