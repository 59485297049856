import {Api} from './apiDefinition.js'

export const getCampaignReport = async ({params}) => {
  const {data} = await Api.get('/campaigns/report', {params, responseType: 'blob'})
  return data
}
export const getCampaigns = async ({params}) => {
  const {data} = await Api.get('/campaigns', {params})
  return data
}
export const postCampaign = async ({payload}) => {
  const {data} = await Api.post('/campaigns', payload)
  return data
}
export const patchCampaign = async ({params, payload}) => {
  const {data} = await Api.patch('/campaigns', payload, {params})
  return data
}
export const deleteCampaign = async ({params}) => {
  await Api.delete('/campaigns', {params})
}
export const postCampaignEmailsSend = async ({params, payload}) => {
  const {data} = await Api.post('/campaigns/emails/send', payload, {params})
  return data
}
export const postCampaignValidate = async ({payload}) => {
  const {data} = await Api.post('/campaigns/validate', payload)
  return data
}
export const getCampaignsOutreachTrackerCompanies = async ({params}) => {
  const {data} = await Api.get('/campaigns/outreach-tracker/companies', {
    params
  })
  return data
}
export const getCampaignsOutreachTrackerPeople = async ({params}) => {
  const {data} = await Api.get('/campaigns/outreach-tracker/people', {
    params
  })
  return data
}
export const getCampaignMetaData = async ({params}) => {
  const {data} = await Api.get('/campaigns/outreach-tracker/meta-data', {
    params
  })
  return data
}
