import {atom} from 'recoil'
import {sessionStorageEffect} from './effects.js'

//ATOMS
export const messageInstructions = atom({
  key: 'messageInstructions',
  default: {
    messageInstructionsId: null,
    label: null,
    language: 'English',
    title: null,
    companyName: null,
    valueProposition: null,
    intention: null,
    preferences: [
      'The email subject should be short and catchy',
      'Keep the email concise',
      'Keep a casual tone',
      'Start the message with a personal introduction'
    ],
    outreachMethod: 'email',
    outreachStage: 'initial_message',
    usedInCampaign: false,
    messageSignature: null
  },
  effects: [sessionStorageEffect('messageInstructions')]
})
