import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Spinner} from '../components/atoms/Spinner.js'
import {useState, useEffect, useCallback} from 'react'
import {debounce} from 'lodash'

export const useAutosizeTextArea = (ref, value, show) => {
  useEffect(() => {
    const resizeTextArea = () => {
      if (ref.current) {
        ref.current.style.height = '0px'
        const newHeight = `${ref.current.scrollHeight}px`
        ref.current.style.height = newHeight
      }
    }

    if (show === undefined || show) {
      resizeTextArea()
    }
  }, [value, show, ref])
}

export const InputWrapper = ({
  isLoading = false,
  tooltipMessage = null,
  tooltipId = null,
  children
}) => {
  if (isLoading) {
    return (
      <Form.Control
        as='div'
        className='d-flex align-items-center justify-content-center'
      >
        <Spinner
          animation={'grow'}
          size={'sm'}
        />
      </Form.Control>
    )
  }

  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip
          id={tooltipId}
          style={tooltipMessage ? {position: 'fixed'} : {display: 'none'}}
        >
          {tooltipMessage}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [isPending, setIsPending] = useState(false)

  const debouncedSet = useCallback(
    debounce((nextValue) => {
      setDebouncedValue(nextValue)
      setIsPending(false)
    }, delay),
    [delay]
  )

  useEffect(() => {
    setIsPending(true)
    debouncedSet(value)
    return () => {
      debouncedSet.cancel()
      setIsPending(false)
    }
  }, [value, debouncedSet])

  return [debouncedValue, isPending]
}

export const validateField = (value, fieldName) => {
  if (!value) return `${fieldName} is a required field`
  if (!value.trim()) return `${fieldName} is a required field`

  const allowedSpecialChars = ' ,.()&\'-/:?!%#@=~|₹$₣¥£€"\n\r'
  const invalidRegex = new RegExp(`[^\\p{L}\\p{N}${allowedSpecialChars}]`, 'gu')
  const invalidCharacters = value.match(invalidRegex)
  if (invalidCharacters && invalidCharacters.length > 0) {
    const hasInvisibleChars = invalidCharacters.some((char) =>
      /[\u200B-\u200D\uFEFF\u2060]/.test(char)
    )
    if (hasInvisibleChars) {
      return `${fieldName} contains invalid invisible characters. Likely due to being copied from non standardized sources. Please try changing your input.`
    } else {
      return `${fieldName} contains invalid characters: ${invalidCharacters.join('')}`
    }
  }
  return null
}

export const isValidUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export function isTruthy(value) {
  if (value === null || value === undefined) {
    return false
  }
  if (typeof value === 'boolean') {
    return value
  }
  if (typeof value === 'number') {
    return !isNaN(value) && Number.isInteger(value)
  }
  if (typeof value === 'string') {
    return value !== ''
  }
  if (Array.isArray(value)) {
    return value.length > 0
  }
  if (typeof value === 'function') {
    return true
  }
  if (typeof value === 'object') {
    return Object.keys(value).length > 0
  } else {
    throw new TypeError('Unsupported type')
  }
}

export function getColorBasedOnScore(score) {
  const red = 255 * (1 - score / 100)
  const green = 255 * (score / 100)
  return `rgb(${Math.round(red)}, ${Math.round(green)}, 0)`
}
