import {Container, Col} from 'react-bootstrap'
import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'
import {useGetKeepAlive} from '../../../hooks/server/useUtilitiesServerState.js'
import {Paper} from '@mui/material'
import {Spinner} from '../../atoms/Spinner.js'
import {useNavigate} from 'react-router-dom'

export const Status = () => {
  const navigate = useNavigate()

  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  const {
    data: servicesStatus,
    isFetching: servicesStatusIsFetching,
    isLoading: servicesStatusIsLoading
  } = useGetKeepAlive()

  const allServicesHealthy =
    servicesStatus && servicesStatus.every((service) => service.healthy === true)

  return (
    <InfoPageTemplate>
      <Container
        id='status'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h1 className='d-text__font--heading d-text__placement--center color--p-dark'>
            Platform Status
          </h1>
          <>
            {servicesStatusIsFetching || servicesStatusIsLoading ? (
              <Spinner style={{marginTop: '1rem'}} />
            ) : (
              <>
                {allServicesHealthy ? (
                  <>
                    <h3
                      style={{padding: '1rem'}}
                      className='d-text__font--subheading d-text__placement--center color--s-dark'
                    >
                      All Systems
                      <span style={{color: 'green'}}>{' Operational'}</span>
                    </h3>
                    <p className='d-text__font--body d-text__placement--center color--p-dark'>
                      In case you are experiencing any issues, please
                      <span
                        className='d-text__font--body d-text__placement--center color--accent clickable'
                        onClick={() => navigate('/contact#contact-us')}
                      >
                        {' contact customer support'}
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <h3
                      style={{padding: '1rem'}}
                      className='d-text__font--subheading d-text__placement--center color--s-dark'
                    >
                      Systems Currently
                      <span style={{color: 'red'}}>{' Unstable'}</span>
                    </h3>
                    <p className='d-text__font--body d-text__placement--center color--p-dark'>
                      We are working on resolving the instability. Thank you for your
                      patience.
                    </p>
                  </>
                )}
                <Paper
                  className='background--s-light d-flex flex-column justify-content-center align-items-center'
                  elevation={1}
                  style={{
                    margin: '2rem',
                    padding: '1rem',
                    width: '80%'
                  }}
                >
                  {servicesStatus.map((service, index) => (
                    <div
                      key={index}
                      className='d-flex justify-content-between align-items-center background--p-light'
                      style={{
                        width: '100%',
                        margin: '0.5rem',
                        borderRadius: '0.5rem'
                      }}
                    >
                      <div
                        style={{
                          width: '50%',
                          background: service.healthy
                            ? 'rgba(0, 128, 0, 0.1)'
                            : 'rgba(128, 0, 0, 0.1)',
                          height: '100%',
                          borderRadius: '0.5rem 0 0 0.5rem'
                        }}
                      >
                        <p
                          className='d-text__font--heading d-text__placement--center color--p-dark'
                          style={{textAlign: 'left', padding: '1rem'}}
                        >
                          {service.service}
                        </p>
                      </div>

                      {service.healthy ? (
                        <span
                          className={'material-symbols-outlined'}
                          style={{color: 'green', padding: '1rem'}}
                        >
                          {'check'}
                        </span>
                      ) : (
                        <span
                          className={'material-symbols-outlined'}
                          style={{color: 'red', padding: '1rem'}}
                        >
                          {'close'}
                        </span>
                      )}
                    </div>
                  ))}
                </Paper>
              </>
            )}
          </>
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
