import axios from 'axios'

export const getClientConfig = async () => {
  const {data} = await axios.get('/api/utilities/configuration')
  return data
}

export const getKeepAlive = async () => {
  const {data} = await axios.get('/api/utilities/keep-alive')
  return data
}

export const getLottieAnimation = async ({file}) => {
  const response = await fetch(`/${file}`)
  const data = await response.json()
  return data
}
