import {atom} from 'recoil'
import {sessionStorageEffect} from './effects.js'

//ATOMS
export const generatedMessageAtom = atom({
  key: 'generatedMessage',
  default: {
    header: null,
    body: null,
    bodyRaw: null
  },
  effects: [sessionStorageEffect('generatedMessage')]
})

export const inputMessagesAtom = atom({
  key: 'inputMessages',
  default: [],
  effects: [sessionStorageEffect('inputMessages')]
})

export const enrichmentPersonUrlAtom = atom({
  key: 'enrichmentPersonUrl',
  default: null,
  effects: [sessionStorageEffect('enrichmentPersonUrl')]
})

export const enrichmentCompanyUrlAtom = atom({
  key: 'enrichmentCompanyUrl',
  default: null,
  effects: [sessionStorageEffect('enrichmentCompanyUrl')]
})
