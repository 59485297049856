import {createContext, useState, useEffect} from 'react'
import {
  InsufficientCreditsError,
  InsufficientProfileInformationError,
  CustomAxiosError,
  CustomFirebaseError
} from '../utils/customErrors.js'

export const ErrorContext = createContext()
export const ErrorProvider = ({children}) => {
  const [errorTitle, setErrorTitle] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleError = (error) => {
    console.error(error)
    switch (true) {
      case error instanceof InsufficientCreditsError:
      case error instanceof InsufficientProfileInformationError:
      case error instanceof CustomAxiosError:
      case error instanceof CustomFirebaseError:
        setErrorTitle(error?.title)
        setErrorMessage(error?.message)
        break
      default:
        setErrorTitle('An error occurred')
        setErrorMessage(error?.toString().replace('Error: ', ''))
    }
  }

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      console.error('Unhandled rejection:', event?.reason)
      try {
        console.error('Error:', event?.reason?.error)
      } catch (e) {
        console.error('Could not explain error.')
      }
      setErrorTitle('An error occurred')
      setErrorMessage(event?.reason?.toString())
      event.preventDefault()
    }

    const handleError = (event) => {
      console.error('Error caught by window.onerror:', event?.message)
      try {
        console.error('Error:', event?.message?.error)
      } catch (e) {
        console.error('Could not explain error.')
      }
      setErrorTitle('An error occurred')
      setErrorMessage(event?.message)
      event.preventDefault()
    }

    window.addEventListener('unhandledrejection', handleUnhandledRejection)
    window.addEventListener('error', handleError)
    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection)
      window.removeEventListener('error', handleError)
    }
  }, [])

  return (
    <ErrorContext.Provider
      value={{
        errorMessage,
        errorTitle,
        setErrorTitle,
        setErrorMessage,
        handleError
      }}
    >
      {children}
    </ErrorContext.Provider>
  )
}
