import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery
} from '@tanstack/react-query'
import * as audienceApi from '../../services/audienceApi.js'
import {useToast} from '../client/useToastsClientState.js'

export const usePostAudience = () => {
  const queryClient = useQueryClient()
  const {addToast} = useToast()
  return useMutation({
    mutationFn: ({payload}) => audienceApi.postAudience({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['audiences']})
      addToast({
        header: `Audience Saved!`,
        body: 'Your audience has been successfully saved!'
      })
    },
    onError: () => {
      addToast({
        header: 'Saving Audience Failed.',
        body: 'Something went wrong when saving your audience. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const useGetAudiences = ({params} = {}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['audiences', params],
    queryFn: () => audienceApi.getAudiences({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'Fetching Audiences Failed.',
        body: 'Something went wrong when fetching your audiences. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePatchAudience = ({showToast = true} = {}) => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) => audienceApi.patchAudience({params, payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['audiences']})
      if (showToast) {
        addToast({
          header: `Audience Updated!`,
          body: 'Your audiences has been successfully updated!'
        })
      }
    },
    onError: () => {
      addToast({
        header: 'Updating Audience Failed.',
        body: 'Something went wrong when updating your audience. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const useDeleteAudience = () => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => audienceApi.deleteAudience({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['audiences']})
      addToast({
        header: `Audience Deleted!`,
        body: 'Your audience has been successfully deleted!'
      })
    },
    onError: () => {
      addToast({
        header: 'Deleting Audience Failed.',
        body: 'Something went wrong when deleting your audience. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const useGetSearchFilterOptions = ({params, enabled = true}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['searchFilterOptions', params],
    queryFn: () => audienceApi.getSearchFilterOptions({params}),
    enabled: !!enabled,
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'Fetching Filters Failed.',
        body: 'Something went wrong when fetching the filter options. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const usePostSearchPeople = ({params, payload, enabled = true}) => {
  const {addToast} = useToast()
  return useInfiniteQuery({
    queryKey: ['audiencePeople', payload, params?.companies_found],
    queryFn: ({pageParam = 0}) =>
      audienceApi.postSearchPeople({
        params: {...params, page: pageParam},
        payload
      }),
    enabled: !!enabled,
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.query.page
      const numberOfPages = lastPage.total_search_results / 10 - 1
      return currentPage < numberOfPages ? currentPage + 1 : undefined
    },
    initialPageParam: 0,
    onError: () => {
      addToast({
        header: 'Fetching People Failed.',
        body: 'Something went wrong when fetching people. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const usePostSearchCompanies = ({payload, params, enabled = true}) => {
  const {addToast} = useToast()
  return useInfiniteQuery({
    queryKey: ['audienceCompanies', payload, params.size],
    queryFn: ({pageParam = 1}) =>
      audienceApi.postSearchCompanies({
        params: {...params, page: pageParam},
        payload
      }),
    enabled: !!enabled,
    staleTime: 6 * 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.meta.currentPage
      const numberOfPages = lastPage.meta.lastPage
      return currentPage < numberOfPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
    onError: () => {
      addToast({
        header: 'Fetching Companies Failed.',
        body: 'Something went wrong when fetching companies. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const useGetEnrichmentPerson = ({params, enabled = true}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['personEnrichment', params],
    queryFn: () => audienceApi.getEnrichmentPerson({params}),
    enabled: !!enabled,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'Fetching Person Information Failed.',
        body: 'Something went wrong when fetching information about the recipient. Please try a different URL.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const useGetEnrichmentCompany = ({params, enabled = false}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['companyEnrichment', params],
    queryFn: () => audienceApi.getEnrichmentCompany({params}),
    enabled: !!enabled,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'Fetching Company Information Failed.',
        body: 'Something went wrong when fetching information about the company. Please try a different URL.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
