import {useState, useEffect, useRef, useCallback} from 'react'

export function InfiniteLooper({speed, direction, children}) {
  const [looperInstances, setLooperInstances] = useState(1)
  const outerRef = useRef(null)
  const innerRef = useRef(null)

  const setupInstances = useCallback(() => {
    if (!innerRef.current || !outerRef.current) return

    const {width} = innerRef.current.getBoundingClientRect()
    const {width: parentWidth} = outerRef.current.getBoundingClientRect()
    const instanceWidth = width / innerRef.current.children.length

    if (width < parentWidth + instanceWidth) {
      setLooperInstances(looperInstances + Math.ceil(parentWidth / width))
    }
  }, [looperInstances])

  useEffect(() => {
    setupInstances()
    window.addEventListener('resize', setupInstances)

    return () => {
      window.removeEventListener('resize', setupInstances)
    }
  }, [setupInstances])

  const resetAnimation = useCallback(() => {
    if (innerRef.current) {
      innerRef.current.setAttribute('data-animate', 'false')

      setTimeout(() => {
        if (innerRef.current) {
          innerRef.current.setAttribute('data-animate', 'true')
        }
      }, 50)
    }
  }, [])

  useEffect(() => {
    setupInstances()
    resetAnimation()
  }, [setupInstances, resetAnimation])

  return (
    <div
      className='looper'
      ref={outerRef}
    >
      <div
        className='looper__innerList'
        ref={innerRef}
        data-animate='true'
      >
        {[...Array(looperInstances)].map((_, ind) => (
          <div
            key={ind}
            className='looper__listInstance'
            style={{
              animationDuration: `${speed}s`,
              animationDirection: direction === 'right' ? 'reverse' : 'normal'
            }}
          >
            {children}
          </div>
        ))}
      </div>
    </div>
  )
}
