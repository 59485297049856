import {Spinner} from '../components/atoms/Spinner.js'
import {useState, useEffect} from 'react'

export function findValueByKey(
  key, //key
  options //[[key, value]]
) {
  const option = options?.find(([optionKey]) => optionKey === key)
  return option ? option[1] : 'Unknown'
}

export function formatDashedString(str) {
  return str
    ?.split('-')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ')
}

export function formatUnderscoreString(str) {
  return str
    ?.split('_')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ')
}

export function formatDate(dateString, includeTime = true) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  if (includeTime) {
    options.hour = '2-digit'
    options.minute = '2-digit'
    options.second = '2-digit'
    options.hour12 = false
  }
  try {
    const date = new Date(dateString)
    return new Intl.DateTimeFormat('default', options).format(date)
  } catch {
    return ''
  }
}

export const getCheckedValues = (form, groupName) => {
  const checkboxes = form.querySelectorAll(`input[name="${groupName}"]:checked`)
  const checkedValues = Array.from(checkboxes)
    .map((box) => box.value)
    .join(',')
  return checkedValues
}

export const getOutreachStatusLabel = (status) => {
  const statusMessages = {
    skipped: 'Skipped',
    awaiting_people: 'In Queue',
    awaiting_message: 'In Queue',
    awaiting_outreach: 'In Queue',
    sent: 'Sent',
    received_response_from_recipient: 'Received Response',
    received_no_response: 'Sent',
    received_bounce: 'Received Bounce'
  }

  return statusMessages[status]
}

export const getSkipReasonLabel = (skipReason) => {
  const SkipReasonMessages = {
    missing_company_name: 'Insufficient Information',
    missing_relevant_people: 'Missing relevant people',
    missing_person_linkedin_id: 'Insufficient Information',
    missing_person_summary: 'Insufficient Information',
    missing_company_linkedin_id: 'Insufficient Information',
    missing_company_summary: 'Insufficient Information',
    missing_person_email: 'Missing Email',
    found_better_candidate: 'Found Better Candidate',
    failed_sending_email: 'Failed Sending Email',
    person_exists_for_multiple_companies: 'Found Better Candidate'
  }
  return SkipReasonMessages[skipReason]
}

export function useBreakpoints() {
  const breakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400
  }

  const [breakpointsState, setBreakpointsState] = useState({
    isXs: window.innerWidth < breakpoints.xs,
    isSm: window.innerWidth >= breakpoints.xs && window.innerWidth < breakpoints.sm,
    isMd: window.innerWidth >= breakpoints.sm && window.innerWidth < breakpoints.md,
    isLg: window.innerWidth >= breakpoints.md && window.innerWidth < breakpoints.lg,
    isXl: window.innerWidth >= breakpoints.lg && window.innerWidth < breakpoints.xl,
    isXxl: window.innerWidth >= breakpoints.xl
  })

  useEffect(() => {
    const handleResize = () => {
      setBreakpointsState({
        isXs: window.innerWidth < breakpoints.xs,
        isSm: window.innerWidth >= breakpoints.xs && window.innerWidth < breakpoints.sm,
        isMd: window.innerWidth >= breakpoints.sm && window.innerWidth < breakpoints.md,
        isLg: window.innerWidth >= breakpoints.md && window.innerWidth < breakpoints.lg,
        isXl: window.innerWidth >= breakpoints.lg && window.innerWidth < breakpoints.xl,
        isXxl: window.innerWidth >= breakpoints.xl
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return breakpointsState
}

export function buildMessageThread({inputMessageIds, messages, sender, recipient}) {
  const baseDate = new Date()
  baseDate.setHours(baseDate.getHours() - inputMessageIds.length)

  const messageMap = new Map(messages?.map((msg) => [msg.message_id, msg]))

  return inputMessageIds.map((messageId, index) => {
    const message = messageMap.get(messageId)
    return {
      sender: sender,
      recipient: recipient,
      date: new Date(baseDate.getTime() + index * 3600000).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }),
      body: message?.message_body_raw || message?.message_body
    }
  })
}

export const buildCompanySearchFilters = (companySearchSettingsAtom) => ({
  revenue: {
    values: companySearchSettingsAtom.revenueValues,
    operator: companySearchSettingsAtom.revenueOperator,
    sign: companySearchSettingsAtom.revenueSign
  },
  totalEmployees: {
    values: companySearchSettingsAtom.employeesValues,
    operator: companySearchSettingsAtom.employeesOperator,
    sign: companySearchSettingsAtom.employeesSign
  },
  technologies: {
    values: companySearchSettingsAtom.technologyValues,
    operator: companySearchSettingsAtom.technologyOperator,
    sign: companySearchSettingsAtom.technologySign
  },
  industryMain: {
    values: companySearchSettingsAtom.industryValues,
    operator: companySearchSettingsAtom.industryOperator,
    sign: companySearchSettingsAtom.industrySign
  },
  country_code: {
    values: companySearchSettingsAtom.countryValues,
    operator: companySearchSettingsAtom.countryOperator,
    sign: companySearchSettingsAtom.countrySign
  },
  name: {
    values: companySearchSettingsAtom.nameValues,
    operator: companySearchSettingsAtom.nameOperator,
    sign: companySearchSettingsAtom.nameSign
  },
  linkedinId: {
    values: companySearchSettingsAtom.linkedinIdValues,
    operator: companySearchSettingsAtom.linkedinIdOperator,
    sign: companySearchSettingsAtom.linkedinIdSign
  },
  domain: {
    values: companySearchSettingsAtom.domainValues,
    operator: companySearchSettingsAtom.domainOperator,
    sign: companySearchSettingsAtom.domainSign
  }
})

export const buildPersonSearchFilters = (personSettingsAtom) => ({
  seniorities: personSettingsAtom.seniorityValues,
  departments: personSettingsAtom.departmentValues,
  countries: personSettingsAtom.countryValues,
  organization_linkedin_public_identifiers:
    personSettingsAtom.organizationLinkedinPublicIdentifiers,
  keywords: personSettingsAtom.keywordValues
})
