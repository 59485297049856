//ATOMS
import {Badge, Stack, Form, FloatingLabel, Dropdown, Container} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'

//MOLECULES
import {DynamicSelector} from '../molecules/DynamicSelector.js'
import {MultiInputField} from '../molecules/MultiInputField.js'
import {FileUploader} from '../molecules/FileUploader.js'

// available: [[value, label], ...]
const CheckboxValues = ({available, selected, setSelected}) => {
  const handleCheckboxChange = (value) => {
    const newSelectedSet = new Set(selected)
    if (newSelectedSet.has(value)) {
      newSelectedSet.delete(value)
    } else {
      newSelectedSet.add(value)
    }
    setSelected([...newSelectedSet])
  }

  return (
    <Form className='d-form'>
      {available.map(([value, label]) => (
        <Form.Check
          style={{whiteSpace: 'nowrap', marginRight: '0.5rem'}}
          key={value}
          label={label}
          value={value}
          type='checkbox'
          checked={selected.includes(value)}
          onChange={(e) => handleCheckboxChange(e.target.value)}
          id={`checkbox-${value}`}
        />
      ))}
    </Form>
  )
}

const Sign = ({available, selected, setSelected}) => {
  return (
    <Form className='d-form'>
      <FloatingLabel
        label='Selection'
        className='d-text__font--heading'
      >
        <Form.Select
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value)
          }}
          style={{minWidth: '12rem'}}
        >
          {available.map(([value, label], index) => (
            <option
              key={index}
              value={value}
            >
              {label}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Form>
  )
}

const Operator = ({available, selected, setSelected}) => {
  return (
    <Form className='d-form'>
      <FloatingLabel
        label='Combination'
        className='d-text__font--heading'
      >
        <Form.Select
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value)
          }}
          style={{minWidth: '9rem'}}
        >
          {available.map(([value, label], index) => (
            <option
              key={index}
              value={value}
            >
              {label}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Form>
  )
}
const getIcon = (filter) => {
  if (filter?.iconName) {
    return (
      <span
        className='material-symbols-outlined'
        style={{fontSize: '1.728rem'}}
      >
        {filter.iconName}
      </span>
    )
  } else if (filter?.IconComponent) {
    const Icon = filter.IconComponent
    return (
      <Icon
        className='fill--s-dark'
        style={{
          width: '1.44rem',
          height: '1.44rem',
          margin: '0.144rem',
          borderRadius: '0.25rem'
        }}
      />
    )
  } else {
    return null
  }
}

export const AudienceSearchBar = ({filters}) => {
  return (
    <Container
      fluid
      className='d-flex justify-content-center align-items-center'
    >
      {filters.map((filter, index) => (
        <Dropdown
          key={index}
          align='start'
          style={{
            padding: '0.5rem 1rem 0.5rem 1rem',
            borderRight:
              index + 1 < filters.length ? 'solid 1px var(--bs-border-color)' : 'none'
          }}
          autoClose='outside'
        >
          <Dropdown.Toggle
            variant='none'
            bsPrefix='dropdownToggle'
            style={{border: 'none', padding: 0}}
            className='d-flex align-items-center color--s-dark fill--s-dark clickable transition-medium t-color--accent t-fill--accent'
            id={`dropdown-${index}`}
          >
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <div
                style={{padding: 0, margin: 0, lineHeight: 0}}
                className='d-flex align-items-center justify-content-center'
              >
                {getIcon(filter)}
                {filter.values.selected.length > 0 && (
                  <Badge
                    style={{
                      marginLeft: '0.25rem',
                      backgroundColor: 'var(--secondary-dark) !important'
                    }}
                    pill
                  >
                    {filter.values.selected.length > 99
                      ? '99+'
                      : filter.values.selected.length}
                  </Badge>
                )}
              </div>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  lineHeight: '1rem'
                }}
              >
                {filter.label}
              </p>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              padding: '1rem',
              width: 'auto',
              minWidth: '15rem',
              whiteSpace: 'nowrap'
            }}
          >
            {filter.values.loading ? (
              <Spinner style={{padding: '1rem'}} />
            ) : (
              <>
                <div
                  className='d-flex align-items-center justify-content-between color--s-dark fill--s-dark'
                  style={{marginBottom: '0.75rem'}}
                >
                  <p style={{margin: '0rem 0.5rem 0rem 0rem'}}>{filter.description}</p>
                  {getIcon(filter)}
                </div>
                <Stack
                  direction='horizontal'
                  gap={2}
                  style={{
                    margin: '0rem 0rem 1rem 0rem',
                    padding: 0,
                    whiteSpace: 'nowrap',
                    flexWrap: 'nowrap'
                  }}
                >
                  {filter?.sign?.available && (
                    <Sign
                      available={filter.sign.available}
                      selected={filter.sign.selected}
                      setSelected={filter.sign.setSelected}
                    />
                  )}
                  {filter?.operator?.available && (
                    <Operator
                      available={filter.operator.available}
                      selected={filter.operator.selected}
                      setSelected={filter.operator.setSelected}
                    />
                  )}
                </Stack>
                {filter.values.displayAs === 'checkbox' && (
                  <CheckboxValues
                    available={filter.values.available}
                    selected={filter.values.selected}
                    setSelected={filter.values.setSelected}
                  />
                )}
                {filter.values.displayAs === 'searchBox' && (
                  <DynamicSelector
                    options={filter.values.available}
                    selectedValues={filter.values.selected}
                    onChange={(e) =>
                      filter.values.setSelected(e.map((obj) => obj.value))
                    }
                  />
                )}
                {filter.values.displayAs === 'multiInput' && (
                  <div className='d-flex justify-content-between align-items-center'>
                    <MultiInputField
                      selectedValues={filter.values.selected}
                      onChange={filter.values.setSelected}
                    />
                    <FileUploader
                      id={`file-input-${index}`}
                      onFileLoaded={(newValues) => filter.values.setSelected(newValues)}
                      style={{fontSize: '1.728rem', marginLeft: '0.25rem'}}
                    />
                  </div>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </Container>
  )
}
