export class InsufficientCreditsError extends Error {
  constructor(message, extra) {
    super(message)
    this.title = 'Insufficient credit'
    this.message =
      'Seems you are missing credits for this operation. Please first refill your credits.'
    this.name = 'InsufficientCreditsError'
    this.extra = extra

    console.error('InsufficientCreditsError initialized with:', message, extra)
  }
}

export class InsufficientProfileInformationError extends Error {
  constructor(message, extra) {
    super(message)
    this.title = 'Missing profile information'
    this.message =
      'Hey there, for this feature to work well we would need to know more you. Please fill in your profile information.'
    this.name = 'InsufficientProfileInformationError'
    this.extra = extra

    console.error('InsufficientProfileInformationError initialized with:', message, extra)
  }
}

const axiosErrorMessages = {
  401: 'Hey there! Your session seems to have expired. Please log in again!',
  422: 'Hey there! Seems this feature is having some troubles. Please hold on while we are looking into it!',
  429: 'Hey there! Our server received a lot of requests from you, please slow down a bit!',
  500: 'Hey there! Seems we are having some internal issues currently. Please try again later!'
}

export class CustomAxiosError extends Error {
  constructor(axiosError) {
    super()
    this.name = 'AxiosError'
    this.title = 'An error occured'
    this.message =
      axiosError.response?.data?.display_message ||
      axiosErrorMessages[axiosError.response?.status] ||
      'An Axios error occurred.'
    this.status = axiosError.response?.status
    this.response = axiosError.response
    this.error = axiosError

    console.error('CustomAxiosError initialized with:', axiosError)
  }
}

const firebaseAuthErrorMessages = {
  'auth/app-deleted': 'The authentication service is unavailable.',
  'auth/app-not-authorized': 'The app is not authorized to use Firebase.',
  'auth/argument-error': 'Invalid arguments provided.',
  'auth/invalid-api-key': 'The provided API key is invalid.',
  'auth/invalid-user-token': 'You must sign in again.',
  'auth/invalid-tenant-id': 'Invalid tenant ID.',
  'auth/network-request-failed': 'Network error. Please try again.',
  'auth/operation-not-allowed': 'Operation not allowed. Enable this provider in Firebase Console.',
  'auth/requires-recent-login': 'Recent sign-in required. Please sign in again.',
  'auth/too-many-requests': 'Too many requests. Please wait and try again.',
  'auth/unauthorized-domain': 'Unauthorized domain.',
  'auth/user-disabled': 'This account has been disabled.',
  'auth/user-token-expired': 'Your session has expired. Please sign in again.',
  'auth/web-storage-unsupported': 'Web storage unsupported on this browser.',
  'auth/wrong-password': 'The provided email and password combination is incorrect.',
  'auth/weak-password': 'The password needs to be at least 6 characters.',
  'auth/email-already-in-use':
    'The provided email address has already been registered with us. Please reset your password instead.',
  'auth/email-not-found':
    'The provided email and password combination is invalid. If you forgot your password, you can reset it below.',
  'auth/user-not-found':
    'The provided email and password combination is invalid. If you forgot your password, you can reset it below.'
}

export class CustomFirebaseError extends Error {
  constructor(firebaseError) {
    super()
    this.name = 'FirebaseError'
    this.title = 'An error occured'
    this.message = firebaseAuthErrorMessages[firebaseError.code] || 'A Firebase error occurred.'
    this.code = firebaseError.code
    this.error = firebaseError

    console.error('CustomFirebaseError initialized with:', firebaseError)
  }
}
