import {Toast as BootstrapToast} from 'react-bootstrap'

export const Toast = ({show, text, header, onClose, className, delay = 5000}) => (
  <BootstrapToast
    onClose={onClose}
    show={show}
    delay={delay}
    autohide
    animation={true}
    className={className}
  >
    <BootstrapToast.Header>{header}</BootstrapToast.Header>
    <BootstrapToast.Body>{text}</BootstrapToast.Body>
  </BootstrapToast>
)
