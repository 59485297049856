import {useRecoilState} from 'recoil'
import {UserAtom} from '../../states/user.js'

export const useUserAtom = () => {
  const [user, setUser] = useRecoilState(UserAtom)

  const setUserKey = (key, user) => {
    setUser((_) => ({..._, [key]: user}))
  }

  return [user, setUser, setUserKey]
}
