//UTILS
import {findValueByKey} from '../../utils/helpers.js'

//ATOMS
import {Spinner} from '../atoms/Spinner.js'
import AsyncSelect from 'react-select/async'

const colors = {
  primaryLight: 'var(--primary-light)',
  accent: 'var(--accent)',
  primaryDark: 'var(--primary-dark)'
}

const LoadingIndicator = () => (
  <>
    <Spinner
      animation='grow'
      size='sm'
    />
    <span style={{marginRight: '10px'}}></span>
  </>
)

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.primaryLight,
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--accent)'
    },
    '&:focus-within': {
      borderColor: 'var(--accent)'
    }
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.primaryLight,
    color: colors.primaryDark,
    zIndex: 999
  }),
  option: (styles, {isFocused}) => ({
    ...styles,
    backgroundColor: isFocused ? colors.accent : colors.primaryLight,
    color: isFocused ? colors.primaryLight : colors.primaryDark,
    '&:active': {
      color: colors.primaryLight,
      backgroundColor: colors.accent
    }
  })
}

export const DynamicSelector = ({
  options = [], //[[value, label],]
  optionsLoading,
  selectedValues = [],
  onChange
}) => {
  const optionsArray = options.map(([value, label]) => ({value, label}))
  const filterOptions = (inputValue, options) => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterOptions(inputValue, optionsArray))
    }, 1000)
  }

  const val = selectedValues.map((value) => ({
    value,
    label: findValueByKey(value, options)
  }))
  return (
    <AsyncSelect
      isMulti
      styles={customStyles}
      components={{LoadingIndicator}}
      cacheOptions
      isDisabled={optionsLoading}
      isLoading={optionsLoading}
      defaultOptions={optionsArray.slice(0, 20)}
      loadOptions={loadOptions}
      value={val}
      onChange={onChange}
    />
  )
}
