import {Modal} from 'react-bootstrap'
import {Button} from '../atoms/Button.js'
import {useNavigate} from 'react-router-dom'

export const UserProfileWarningInfoModal = ({show, onHide}) => {
  const navigate = useNavigate()

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header>Profile information missing!</Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center'
        }}
      >
        <small>
          Seems we are missing some of your profile information needed to generate
          messages.
        </small>
        <Button
          children='Take me there!'
          onClick={() => navigate('/profile')}
        />
      </Modal.Body>
    </Modal>
  )
}
