import {useState, useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'

import {Container, Navbar, Nav, Stack, Col, Dropdown, Collapse} from 'react-bootstrap'
import {Box} from '@mui/material'

import {Button} from '../atoms/Button.js'
import {ReactComponent as PitchrIcon} from '../atoms/assets/PitchrLogo.svg'

import {BaseTemplate} from './BaseTemplate.js'

import {useBreakpoints} from '../../utils/helpers.js'

const NavigationBar = ({contentColBreakpoints, isXs, isSm}) => {
  const navigate = useNavigate()

  const hyperlinks = [
    {
      label: 'Platform',
      redirect: '/#workflow '
    },
    {
      label: 'Contact',
      redirect: '/contact#contact-us'
    },
    {
      label: 'Pricing',
      redirect: '/pricing#pricing'
    }
  ]

  const HomeIcon = () => {
    return (
      <PitchrIcon
        style={{height: '2.5rem', width: 'auto'}}
        className='clickable'
        onClick={() => {
          navigate('/#workflow')
        }}
      />
    )
  }

  return (
    <Navbar
      sticky='top'
      className='d-navbar d-flex justify-content-center'
    >
      {isXs || isSm ? (
        <Col
          className='d-flex flex-row align-items-center justify-content-between'
          style={{padding: '1rem'}}
        >
          <HomeIcon />
          <Stack
            direction='horizontal'
            gap={3}
          >
            <Button
              className='d-button__type--link-light'
              style={{fontSize: '1.44rem'}}
              children={'Get Started'}
              onClick={() => {
                navigate('/demo#schedule')
              }}
              variant='link'
            />

            <Dropdown>
              <Dropdown.Toggle
                as={Button}
                style={{
                  padding: 0,
                  border: 'none',
                  background: 'none',
                  display: 'flex'
                }}
              >
                <span
                  className='material-symbols-outlined color--s-light'
                  style={{fontSize: '3rem'}}
                >
                  {'menu'}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu align='end'>
                {hyperlinks.map((link, index) => (
                  <Dropdown.Item
                    key={index}
                    style={{fontSize: '1.44rem'}}
                    onClick={() => {
                      navigate(link.redirect)
                    }}
                  >
                    {link.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Stack>
        </Col>
      ) : (
        <Col
          {...contentColBreakpoints}
          className='d-flex flex-row align-items-center justify-content-between'
          style={{padding: '0.25rem'}}
        >
          <Stack
            direction='horizontal'
            gap={5}
          >
            <HomeIcon />
            {hyperlinks.map((link, index) => (
              <Nav.Link
                key={index}
                onClick={() => {
                  navigate(link.redirect)
                }}
              >
                <h6 className='d-text__font--heading d-text__placement--center color--p-light transition-medium t-color--accent'>
                  {link.label}
                </h6>
              </Nav.Link>
            ))}
          </Stack>

          <Stack
            direction='horizontal'
            gap={3}
          >
            <Button
              variant='outline-secondary'
              children={'Sign In'}
              onClick={() => {
                navigate('/sign-in')
              }}
            />
            <Button
              variant='outline-secondary'
              children={
                <>
                  {'Get Started'}
                  <span className='material-symbols-outlined'>{'arrow_right_alt'}</span>
                </>
              }
              onClick={() => {
                navigate('/demo#schedule')
              }}
            />
          </Stack>
        </Col>
      )}
    </Navbar>
  )
}

const BannerSegment = () => {
  const navigate = useNavigate()

  //   const [open, setOpen] = useState(false)
  //   const [textIndex, setTextIndex] = useState(0)
  //   const [firstRun, setFirstRun] = useState(true)

  //   const subTexts = ['Target', 'Tailor', 'Connect']

  //   useEffect(() => {
  //     let initialTimeout

  //     const updateText = () => {
  //       setTextIndex((prevIndex) => (prevIndex + 1) % subTexts.length)
  //       setOpen(true)
  //       setTimeout(() => setOpen(false), 2500)
  //     }

  //     if (firstRun) {
  //       initialTimeout = setTimeout(updateText, 2500)
  //       setFirstRun(false)
  //     } else {
  //       updateText()
  //     }

  //     const interval = setInterval(updateText, 3500)

  //     return () => {
  //       clearTimeout(initialTimeout)
  //       clearInterval(interval)
  //     }
  //   }, [firstRun])

  return (
    <>
      <h1
        className='d-text__font--heading color--p-light'
        style={{textAlign: 'center'}}
      >
        Simplify Your Outbound Sales
      </h1>
      <h2
        className='d-text__font--subheading color--s-light'
        style={{textAlign: 'center'}}
      >
        Target, tailor & connect.
      </h2>
      <p
        className='d-text__font--body color--p-light'
        style={{textAlign: 'center', textWrap: 'balance', marginTop: '0.2rem'}}
      >
        Define your ideal audience, craft the perfect offer, and let our AI automate
        your outreach. It’s outbound sales made effortless.
      </p>

      {/* <div
        style={{height: '3.3em', width: '100%'}}
        className='d-flex flex-column justify-content-center align-items-center'
      >
        <Collapse
          in={open}
          dimension={'width'}
        >
          <div>
            <h2
              className='d-text__font--subheading color--p-light d-text__placement--center'
              style={{textWrap: 'nowrap'}}
            >
              {subTexts[textIndex]}
            </h2>
          </div>
        </Collapse>
      </div> */}

      <Button
        children='Schedule A Demo'
        style={{marginTop: '2rem'}}
        variant='outline-secondary'
        onClick={() => {
          navigate('/demo#schedule')
        }}
      />
    </>
  )
}

const FooterSegment = () => {
  const navigate = useNavigate()

  const styleClass = 'd-text__font--subheading color--p-light'
  const sections = [
    {
      header: 'Legal',
      items: [
        {
          text: 'Terms & Conditions',
          redirect: '/terms-and-conditions#terms-and-conditions'
        },
        {text: 'Privacy Policy', redirect: '/privacy-policy#privacy-policy'}
      ]
    },
    {
      header: 'Platform',
      items: [
        {text: 'Pricing', redirect: '/pricing#pricing'},
        {text: 'Data', redirect: '/data#data'},
        {text: 'Features', redirect: '/#features'},
        {text: 'Use Cases', redirect: '/#use-cases'},
        {text: 'Status', redirect: '/status#status'}
      ]
    },
    {
      header: 'Contact',
      items: [
        {text: 'Schedule Demo', redirect: '/demo#schedule'},
        {text: 'Contact Us', redirect: '/contact#contact-us'},
        {text: 'Newsletter', redirect: '/newsletter#newsletter'},
        {text: 'Opt Out', redirect: '/opt-out#opt-out'}
      ]
    }
  ]

  return (
    <>
      {sections.map((section, index) => (
        <div
          className='d-flex flex-column align-items-center justify-content-start'
          key={index}
        >
          <h6
            className={styleClass}
            style={{fontWeight: 400}}
          >
            {section.header}
          </h6>
          {section.items.map((item, itemIndex) => (
            <h6
              key={itemIndex}
              className={`${styleClass} clickable transition-fast t-underline--accent`}
              onClick={() => {
                navigate(item.redirect)
              }}
            >
              {`${item.text}`}
            </h6>
          ))}
        </div>
      ))}
      <hr
        style={{width: '100%'}}
        className='color--p-light'
      />
      <Box
        p={2}
        width={280}
      >
        <h6 className='d-text__font--subheading color--s-light'>
          2024 SALES AUTOMATION B.V.
        </h6>
        <p className='d-text__font--body color--s-light'>
          Registered in Netherlands. Chamber of Commerce number 94072590.
        </p>
      </Box>
    </>
  )
}

export const InfoPageTemplate = ({
  children,
  showNavBar = true,
  showBanner = true,
  showFooter = true
}) => {
  const {isXs, isSm} = useBreakpoints()
  const location = useLocation()

  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  useEffect(() => {
    if (!!location.hash) {
      const hash = location.hash.replace('#', '')
      setTimeout(() => {
        const element = document.getElementById(hash)
        if (element) {
          element.scrollIntoView({behavior: 'smooth'})
        }
      }, 100)
    }
  }, [location.pathname, location.hash])

  return (
    <BaseTemplate>
      {showBanner && <section id='banner' />}
      {showNavBar && (
        <NavigationBar
          contentColBreakpoints={contentColBreakpoints}
          isXs={isXs}
          isSm={isSm}
        />
      )}

      {showBanner && (
        <Container
          fluid
          className='background--p-dark d-flex justify-content-center align-items-center'
        >
          <Col
            {...contentColBreakpoints}
            className='d-flex flex-column justify-content-center align-items-center'
            style={{paddingTop: '16rem', paddingBottom: '16rem'}}
          >
            <BannerSegment />
          </Col>
        </Container>
      )}

      {children}

      {showFooter && (
        <Container
          id='footer'
          fluid
          className='background--p-dark d-flex justify-content-center align-items-center'
          style={{paddingTop: '5rem'}}
        >
          <Col
            {...contentColBreakpoints}
            className='d-flex align-items-start justify-content-around flex-wrap'
          >
            <FooterSegment />
          </Col>
        </Container>
      )}
    </BaseTemplate>
  )
}
