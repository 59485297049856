import axios from 'axios'
import {getAuth} from 'firebase/auth'
import {CustomAxiosError} from '../utils/customErrors.js'

export const Api = axios.create({
  baseURL: '/api'
})
Api.interceptors.request.use(
  async (config) => {
    const auth = getAuth()
    if (auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken()
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
    }
    return config
  },
  (error) => {
    throw new CustomAxiosError(error)
  }
)

Api.interceptors.response.use(
  (response) => {
    return response
  },
  async (e) => {
    throw new CustomAxiosError(e)
  }
)
