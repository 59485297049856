import {useRecoilState} from 'recoil'
import {toastAtom} from '../../states/toasts.js'

export const useToast = () => {
  const [toasts, setToasts] = useRecoilState(toastAtom)

  const addToast = ({header, body, className, delay}) => {
    const newToast = {
      id: Date.now(),
      header,
      body,
      className,
      delay
    }
    setToasts((oldToasts) => [...oldToasts, newToast])
  }

  const removeToast = (toastId) => {
    setToasts((oldToasts) => oldToasts.filter((toast) => toast.id !== toastId))
  }

  return {toasts, addToast, removeToast}
}
