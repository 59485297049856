import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useQueryClient} from '@tanstack/react-query'
import {Collapse, Row, Col, Container} from 'react-bootstrap'
import {Paper} from '@mui/material'
import {Spinner} from '../atoms/Spinner.js'

import {UserCreditWarningModal} from '../molecules/UserCreditWarningModal.js'

import {WorkflowStepper} from '../organisms/WorkflowStepper.js'
import {
  CadenceDetails,
  AudienceDetails,
  CampaignDetails,
  SettingsDetails
} from '../organisms/CampaignBuilderInput.js'

import {SidebarTemplate} from '../templates/SidebarTemplate.js'

import {useCampaignAtom} from '../../hooks/client/useCampaignsClientState.js'
import {useToast} from '../../hooks/client/useToastsClientState.js'

import * as AudienceSS from '../../hooks/server/useAudiencesServerState.js'
import * as AgentSS from '../../hooks/server/useAgentServerState.js'
import {
  usePostCampaign,
  useGetCampaigns
} from '../../hooks/server/useCampaignsServerState.js'
import {useGetUserCredit} from '../../hooks/server/useUserServerState.js'

import {useDebounce, isTruthy} from '../../utils/hooks.js'
import {
  checkMaxPeopleOutreach,
  checkMaxPeopleOutreachPerDay,
  checkMaxPeopleOutreachPerCompany,
  calculatePeoplePerCompany,
  calculateLogDiscrepancyRatio,
  calculatePeopleToReach,
  calculateCompaniesToReach,
  checkCompaniesReach,
  checkPeopleReach,
  checkAudiencePeople,
  checkAudienceCompanies,
  calculateMaxPeopleOutreach
} from '../../utils/calculations.js'

export const CampaignBuilder = () => {
  //GLOBAL CLIENT STATES
  const navigate = useNavigate()

  const {campaignAtom, setCampaignAtomKey, campaignStepsToResponse} = useCampaignAtom()
  const {
    campaignSortingInstructions,
    maxOutreachMessages,
    maxOutreachMessagesPerDay,
    maxOutreachPeoplePerCompany
  } = campaignAtom
  const {addToast} = useToast()

  //LOCAL CLIENT STATES
  const [sortingInstructionsDebounce, sortingInstructionsIsDebouncing] = useDebounce(
    campaignSortingInstructions,
    3000
  )

  const [selectedAudience, setSelectedAudience] = useState({})
  const peopleInAudience = selectedAudience?.people_in_audience
  const companiesInAudience = selectedAudience?.companies_in_audience
  const [peopleSearchPayload, setPeopleSearchPayload] = useState({})
  const [peopleToRank, setPeopleToRank] = useState([])
  const [rankedPeopleSearchResults, setRankedPeopleSearchResults] = useState([])
  const [showCreditInfoModal, setShowCreditInfoModal] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const campaignDetailsIsValid =
    isTruthy(campaignAtom?.campaignName) && isTruthy(campaignAtom?.campaignDescription)

  const audienceAlreadyInUse =
    isTruthy(selectedAudience) && isTruthy(selectedAudience?.used_in_campaign)
  const audienceCompaniesError = checkAudienceCompanies({companiesInAudience})
  const audiencePeopleError = checkAudiencePeople({
    companiesInAudience,
    peopleInAudience
  })
  const audienceIsValid =
    isTruthy(selectedAudience) &&
    !audienceAlreadyInUse &&
    !isTruthy(audienceCompaniesError) &&
    !isTruthy(audiencePeopleError)
  const audienceDetailsAreValid =
    isTruthy(campaignSortingInstructions) && audienceIsValid

  const stepsAreValid =
    isTruthy(campaignAtom?.steps) &&
    campaignAtom?.steps?.every(
      (step) => isTruthy(step?.messageInstructionsId) && isTruthy(step?.index)
    )

  const maxPeopleOutreach = calculateMaxPeopleOutreach({
    maxOutreachMessages,
    maxOutreachPerPerson: campaignAtom?.steps?.length
  })
  const peoplePerCompany = calculatePeoplePerCompany({
    peopleInAudience,
    companiesInAudience
  })
  const companiesToReach = calculateCompaniesToReach({
    maxOutreachPeoplePerCompany,
    maxPeopleOutreach,
    peoplePerCompany,
    companiesInAudience
  })
  const peopleToReach = calculatePeopleToReach({
    companiesToReach,
    maxOutreachPeoplePerCompany,
    maxPeopleOutreach,
    peopleInAudience
  })
  const logDiscrepancyRatio = calculateLogDiscrepancyRatio({
    maxOutreachPeoplePerCompany,
    peoplePerCompany
  })
  const maxPeopleOutreachError = checkMaxPeopleOutreach({
    maxPeopleOutreach,
    maxOutreachMessagesPerDay,
    maxOutreachPeoplePerCompany
  })
  const maxPeopleOutreachPerDayError = checkMaxPeopleOutreachPerDay({
    maxOutreachMessagesPerDay
  })
  const maxPeopleOutreachPerCompanyError = checkMaxPeopleOutreachPerCompany({
    maxOutreachPeoplePerCompany
  })

  const companiesToReachError = checkCompaniesReach({
    companiesToReach,
    companiesInAudience
  })
  const peopleToReachError = checkPeopleReach({
    peopleToReach,
    peopleInAudience,
    logDiscrepancyRatio
  })
  const outreachInputIsValid =
    isTruthy(maxOutreachMessages) &&
    isTruthy(maxOutreachMessagesPerDay) &&
    isTruthy(maxOutreachPeoplePerCompany) &&
    !isTruthy(maxPeopleOutreachError) &&
    !isTruthy(maxPeopleOutreachPerDayError) &&
    !isTruthy(maxPeopleOutreachPerCompanyError)

  const outreachIsValid =
    outreachInputIsValid &&
    !isTruthy(companiesToReachError) &&
    !isTruthy(peopleToReachError)

  const steps = [
    {
      header: 'Audience',
      icon: 'group',
      informationIsCorrect: audienceIsValid
    },
    {
      header: 'Message',
      icon: 'message',
      informationIsCorrect: stepsAreValid
    },
    {
      header: 'Preferences',
      icon: 'tune',
      informationIsCorrect: outreachInputIsValid
    },
    {
      header: 'Details',
      icon: 'bookmark',
      informationIsCorrect: campaignDetailsIsValid
    }
  ]

  //SERVER STATES
  const queryClient = useQueryClient()

  const {mutateAsync: postCampaign, isPending: postCampaignIsPending} =
    usePostCampaign()
  const {mutateAsync: patchAudience, isPending: patchAudienceIsPending} =
    AudienceSS.usePatchAudience({showToast: false})
  const {
    mutateAsync: patchMessageInstructions,
    isPending: patchMessageInstructionsIsPending
  } = AgentSS.usePatchMessageInstructions({
    showToast: false,
    invalidateQuery: false
  })

  const {data: campaigns, fetchStatus: campaignFetchStatus} = useGetCampaigns({
    params: {per_page: 10000, page: 0}
  })
  const {
    data: userCredit,
    isFetching: userCreditIsFetching,
    isLoading: userCreditIsLoading
  } = useGetUserCredit()
  const {
    data: messageInstructions,
    isLoading: messageInstructionsIsLoading,
    isFetching: messageInstructionsIsFetching
  } = AgentSS.useGetMessageInstructions()
  const {
    data: audiences,
    isLoading: audiencesIsLoading,
    isFetching: audiencesIsFetching
  } = AudienceSS.useGetAudiences()
  const {
    data: companiesSearchResults,
    isFetching: companiesSearchResultsIsFetching,
    isLoading: companiesSearchResultsIsLoading
  } = AudienceSS.usePostSearchCompanies({
    enabled: isTruthy(selectedAudience) && audienceIsValid,
    payload: selectedAudience?.company_search_filters,
    params: {page: 0, size: 40}
  })
  const {
    data: peopleSearchResults,
    isFetching: peopleSearchResultsIsFetching,
    isLoading: peopleSearchResultsIsLoading
  } = AudienceSS.usePostSearchPeople({
    enabled:
      isTruthy(selectedAudience) &&
      isTruthy(peopleSearchPayload) &&
      !companiesSearchResultsIsFetching &&
      !companiesSearchResultsIsLoading &&
      audienceIsValid,
    payload: peopleSearchPayload
  })
  const {
    data: leadsRankResults,
    isFetching: leadsRankResultsFetching,
    isLoading: leadsRankResultsLoading
  } = AgentSS.usePostLeadsRank({
    payload: {
      role_titles: peopleToRank,
      instruction: sortingInstructionsDebounce
    },
    enabled:
      isTruthy(selectedAudience) &&
      isTruthy(peopleSearchPayload) &&
      isTruthy(peopleToRank) &&
      isTruthy(sortingInstructionsDebounce) &&
      !companiesSearchResultsIsFetching &&
      !companiesSearchResultsIsLoading &&
      !peopleSearchResultsIsFetching &&
      !peopleSearchResultsIsLoading &&
      audienceDetailsAreValid
  })

  //Listeners
  useEffect(() => {
    if (
      !userCreditIsLoading &&
      !userCreditIsFetching &&
      userCredit?.available_credit === 0
    ) {
      setShowCreditInfoModal(true)
    }
  }, [userCredit, userCreditIsLoading, userCreditIsFetching])

  useEffect(() => {
    let foundAudience = {}
    if (isTruthy(audiences) && isTruthy(campaignAtom?.audienceId)) {
      foundAudience = audiences?.find((a) => a.audience_id === campaignAtom?.audienceId)
    }
    setSelectedAudience(foundAudience)
  }, [audiences, campaignAtom])

  useEffect(() => {
    let payload = {}
    if (isTruthy(companiesSearchResults)) {
      const linkedinIds =
        companiesSearchResults?.pages?.[0]?.companies
          ?.map((company) => company?.socialNetworks?.linkedinIdAlpha)
          ?.filter((id) => id) || []
      payload = {
        ...selectedAudience?.person_search_filters,
        organization_linkedin_public_identifiers: linkedinIds
      }
    }
    setPeopleSearchPayload(payload)
  }, [companiesSearchResults])

  useEffect(() => {
    let newPeopleToRank = []
    if (isTruthy(peopleSearchResults?.pages?.[0]?.search_results)) {
      newPeopleToRank = peopleSearchResults.pages[0].search_results.map(
        (result, index) => ({
          id: index,
          role_title: result?.role_title || result?.person?.headline
        })
      )
    }
    setPeopleToRank(newPeopleToRank)
  }, [peopleSearchResults])

  useEffect(() => {
    let updatedSearchResults = []
    if (isTruthy(leadsRankResults) && isTruthy(peopleSearchResults)) {
      updatedSearchResults = {...peopleSearchResults}
      updatedSearchResults.pages = updatedSearchResults.pages.map((page) => {
        return {
          ...page,
          search_results: page.search_results
            .map((person) => {
              const rankedPerson = leadsRankResults.role_titles.find((ranked) => {
                return (
                  person.role_title === ranked.role_title ||
                  person.person?.headline === ranked.role_title
                )
              })

              return rankedPerson ? {...person, score: rankedPerson.score} : person
            })
            .sort((a, b) => b.score - a.score)
        }
      })
    }
    setRankedPeopleSearchResults(updatedSearchResults)
  }, [leadsRankResults, peopleSearchResults])

  //Handlers
  const handlePostCampaign = async ({status = 'paused'}) => {
    const campaignPayload = {
      audience_id: campaignAtom.audienceId,
      steps: campaignStepsToResponse(campaignAtom.steps),
      campaign_status: status,
      campaign_name: campaignAtom.campaignName,
      campaign_description: campaignAtom.campaignDescription,
      max_people_outreach: campaignAtom.maxOutreachMessages,
      max_people_outreach_per_company: campaignAtom.maxOutreachPeoplePerCompany,
      max_people_outreach_per_day: campaignAtom.maxOutreachMessagesPerDay,
      campaign_sorting_instructions: campaignAtom.campaignSortingInstructions,
      companies_in_audience: companiesInAudience,
      people_in_audience: peopleInAudience
    }

    const audienceParams = {audience_id: campaignAtom.audienceId}
    const miInUse = new Set()
    campaigns.items.forEach((c) => {
      c.steps.forEach((step) => {
        miInUse.add(step.message_instructions_id)
      })
    })
    let agentParams = []
    campaignAtom.steps.forEach((step) => {
      if (!miInUse.has(step.messageInstructionsId)) {
        agentParams.push({message_instructions_id: step.messageInstructionsId})
      }
    })

    try {
      await postCampaign({payload: campaignPayload})
      const tasks = [
        patchAudience({params: audienceParams, payload: {used_in_campaign: true}})
      ].concat(
        agentParams.map((params) =>
          patchMessageInstructions({params, payload: {used_in_campaign: true}})
        )
      )
      await Promise.all(tasks)
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
    } catch (e) {
      console.error('Error during campaign addition:', e)
    }
  }

  return (
    <>
      <UserCreditWarningModal
        show={showCreditInfoModal}
        onHide={() => setShowCreditInfoModal(false)}
      />
      <SidebarTemplate>
        <Paper
          className='d-flex flex-column align-items-center background--p-light'
          variant='outlined'
          style={{padding: '1rem', borderRadius: '1rem', margin: '2rem'}}
        >
          <Container fluid>
            <h5 className='d-text__font--heading color--p-dark '>Campaign</h5>
            <hr style={{marginTop: 0}} />
            <Row>
              <Col xs='auto'>
                <WorkflowStepper
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              </Col>
              <Col>
                <Collapse in={activeStep === 0}>
                  <div>
                    <AudienceDetails
                      audiences={audiences}
                      audiencesIsFetching={audiencesIsFetching}
                      audiencesIsLoading={audiencesIsLoading}
                      audienceAlreadyInUse={audienceAlreadyInUse}
                      audienceCompaniesError={audienceCompaniesError}
                      audiencePeopleError={audiencePeopleError}
                      audienceIsValid={audienceIsValid}
                      campaignAtom={campaignAtom}
                      companiesSearchResultsIsFetching={
                        companiesSearchResultsIsFetching
                      }
                      companiesSearchResultsIsLoading={companiesSearchResultsIsLoading}
                      peopleSearchResults={peopleSearchResults}
                      peopleSearchResultsIsFetching={peopleSearchResultsIsFetching}
                      peopleSearchResultsIsLoading={peopleSearchResultsIsLoading}
                      rankedPeopleSearchResults={rankedPeopleSearchResults}
                      leadsRankResultsFetching={leadsRankResultsFetching}
                      leadsRankResultsLoading={leadsRankResultsLoading}
                      selectedAudience={selectedAudience}
                      setCampaignAtomKey={setCampaignAtomKey}
                      sortingInstructionsIsDebouncing={sortingInstructionsIsDebouncing}
                    />
                  </div>
                </Collapse>
                <Collapse in={activeStep === 1}>
                  <div>
                    <CadenceDetails
                      stepsAreValid={stepsAreValid}
                      campaignAtom={campaignAtom}
                      setCampaignAtomKey={setCampaignAtomKey}
                      audiences={audiences}
                      messageInstructions={messageInstructions}
                      messageInstructionsIsLoading={messageInstructionsIsLoading}
                      messageInstructionsIsFetching={messageInstructionsIsFetching}
                    />
                  </div>
                </Collapse>
                <Collapse in={activeStep === 2}>
                  <div>
                    <SettingsDetails
                      campaignAtom={campaignAtom}
                      setCampaignAtomKey={setCampaignAtomKey}
                      maxPeopleOutreachError={maxPeopleOutreachError}
                      maxPeopleOutreachPerDayError={maxPeopleOutreachPerDayError}
                      maxPeopleOutreachPerCompanyError={
                        maxPeopleOutreachPerCompanyError
                      }
                      outreachInputIsValid={outreachInputIsValid}
                      companiesToReach={companiesToReach}
                      peopleToReach={peopleToReach}
                      companiesToReachError={companiesToReachError}
                      peopleToReachError={peopleToReachError}
                      peopleInAudience={peopleInAudience}
                      companiesInAudience={companiesInAudience}
                    />
                  </div>
                </Collapse>
                <Collapse in={activeStep === 3}>
                  <div>
                    <CampaignDetails
                      campaignAtom={campaignAtom}
                      setCampaignAtomKey={setCampaignAtomKey}
                      handlePostCampaign={handlePostCampaign}
                      postCampaignIsPending={
                        postCampaignIsPending ||
                        patchAudienceIsPending ||
                        patchMessageInstructionsIsPending
                      }
                      allInputIsCorrect={outreachIsValid && campaignDetailsIsValid}
                    />
                  </div>
                </Collapse>
              </Col>
            </Row>
          </Container>
        </Paper>
      </SidebarTemplate>
    </>
  )
}
