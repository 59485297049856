import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import * as agentApi from '../../services/agentApi.js'
import {useToast} from '../client/useToastsClientState.js'

export const useGetSupportedLanguages = () => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['messageSupportedLanguages'],
    queryFn: () => agentApi.getSupportedLanguages(),
    staleTime: 7 * 24 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'Fetching Supported Message Languages failed.',
        body: 'Something went wrong when retrieving list of supported message languages. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const useGetMessages = ({params} = {}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['messages', params],
    queryFn: () => agentApi.getMessages({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'Fetching Messages failed.',
        body: 'Something went wrong when retrieving your messages. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePostMessage = () => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessage({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      addToast({
        header: `Message Saved!`,
        body: 'Your message has been successfully saved!'
      })
    },
    onError: () => {
      addToast({
        header: 'Saving Message Failed.',
        body: 'Something went wrong when saving your message. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePostLeadsRank = ({payload, enabled = true}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['leadsRank', payload],
    queryFn: () => agentApi.postLeadsRank({payload}),
    enabled: !!enabled,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'AI People Ranking Failed.',
        body: 'Something went wrong when ranking people. Please try again in a bit.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePatchMessage = () => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) => agentApi.patchMessage({params, payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      addToast({
        header: `Message Updated!`,
        body: 'Your message has been successfully updated!'
      })
    },
    onError: () => {
      addToast({
        header: 'Message Update Failed.',
        body: 'Something went wrong when updating your message. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const useDeleteMessage = () => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => agentApi.deleteMessage({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      addToast({
        header: `Message Deleted!`,
        body: 'Your message has been successfully deleted!'
      })
    },
    onError: () => {
      addToast({
        header: 'Message Deletion Failed.',
        body: 'Something went wrong when deleting your message. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePostMessageGenerate = () => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessageGenerate({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['userCredit']})
      addToast({
        header: `Message Generated!`,
        body: 'Your message has been successfully generated!'
      })
    },
    onError: () => {
      addToast({
        header: 'Generating Message Failed.',
        body: 'Something went wrong when generating your message. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const useGetMessageInstructions = ({params} = {}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['messageInstructions', params],
    queryFn: () => agentApi.getMessageInstructions({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'Fetching Message Instructionss Failed.',
        body: 'Something went wrong when retrieving your message instructions. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePostMessageInstructions = () => {
  const queryClient = useQueryClient()
  const {addToast} = useToast()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessageInstructions({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      addToast({
        header: 'Message Instructions Saved!',
        body: 'Your message instructions have been successfully saved!'
      })
    },

    onError: () => {
      addToast({
        header: 'Saving Message Instructions Failed.',
        body: 'Something went wrong when saving your message instructions. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePatchMessageInstructions = ({
  showToast = true,
  invalidateQuery = true
} = {}) => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) =>
      agentApi.patchMessageInstructions({params, payload}),
    onSuccess: () => {
      if (invalidateQuery) {
        queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      }
      if (showToast) {
        addToast({
          header: `Message Instructions Updated!`,
          body: 'Your message instructions have been successfully updated!'
        })
      }
    },
    onError: () => {
      addToast({
        header: 'Message Instructions update Failed.',
        body: 'Something went wrong when updating your message instructions. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const useDeleteMessageInstructions = () => {
  const queryClient = useQueryClient()
  const {addToast} = useToast()
  return useMutation({
    mutationFn: ({params}) => agentApi.deleteMessageInstructions({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      addToast({
        header: `Message Instructions Deleted!`,
        body: 'Your message instructions have been successfully deleted!'
      })
    },
    onError: () => {
      addToast({
        header: 'Message Instructions Deletion Failed.',
        body: 'Something went wrong when deleting your message instructions. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
