import CreatableSelect from 'react-select/creatable'
import {Spinner} from '../atoms/Spinner.js'

const LoadingIndicator = () => (
  <>
    <Spinner
      animation='grow'
      size='sm'
    />
    <span style={{marginRight: '10px'}}></span>
  </>
)

const colors = {
  lightAccent: 'var(--secondary-light)',
  primaryLight: 'var(--primary-light)',
  accent: 'var(--accent)',
  primaryDark: 'var(--primary-dark)'
}

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.primaryLight,
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--accent)'
    },
    '&:focus-within': {
      borderColor: 'var(--accent)'
    }
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.primaryLight,
    color: colors.primaryDark
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
  option: (styles, {isFocused}) => ({
    ...styles,
    backgroundColor: isFocused ? colors.accent : colors.primaryLight,
    color: isFocused ? colors.primaryLight : colors.primaryDark,
    '&:active': {
      color: colors.primaryLight,
      backgroundColor: colors.accent,
      zIndex: 999
    }
  })
}

export const SelectCreateOptions = ({
  options,
  optionsLoading,
  onChange,
  value,
  placeholder = ''
}) => {
  return (
    <CreatableSelect
      styles={customStyles}
      placeholder={placeholder}
      components={{LoadingIndicator}}
      isDisabled={optionsLoading || value?.length < 1}
      isLoading={optionsLoading}
      isClearable
      options={options}
      onChange={onChange}
      menuPortalTarget={document.body}
      value={value}
      noOptionsMessage={() => 'To add an option, please start typing'}
    />
  )
}
