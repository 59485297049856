import {Api} from './apiDefinition.js'

export const getAudiences = async ({params}) => {
  const {data} = await Api.get('/audiences/user/audiences', {params})
  return data
}

export const postAudience = async ({payload}) => {
  const {data} = await Api.post('/audiences/user/audiences', payload)
  return data
}

export const patchAudience = async ({params, payload}) => {
  const {data} = await Api.patch('/audiences/user/audiences', payload, {params})
  return data
}

export const deleteAudience = async ({params}) => {
  await Api.delete('/audiences/user/audiences', {params})
}

export const getSearchFilterOptions = async ({params}) => {
  const {data} = await Api.get('/audiences/search/filter-options', {params})
  return data
}

export const postSearchCompanies = async ({params, payload}) => {
  const {data} = await Api.post('/audiences/search/companies', payload, {
    params
  })
  return data
}

export const postSearchPeople = async ({params, payload}) => {
  const {data} = await Api.post('/audiences/search/people', payload, {params})
  return data
}

export const getEnrichmentPerson = async ({params}) => {
  const {data} = await Api.get('/audiences/enrichment/person', {params})
  return data
}

export const getEnrichmentCompany = async ({params}) => {
  const {data} = await Api.get('/audiences/enrichment/company', {params})
  return data
}
