import {Box, Drawer} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {useSignOut, useGetUserCredit} from '../../hooks/server/useUserServerState.js'
import {ReactComponent as PitchrIcon} from '../atoms/assets/PitchrLogo.svg'
import {Spinner} from '../atoms/Spinner.js'
import {useSidebarClientState} from '../../hooks/client/useSidebarClientState.js'

const DRAWER_WIDTH_OPEN = 11
const DRAWER_WIDTH_CLOSED = 3.5
const TRANSITION_DURATION = 0.2

export const Sidebar = ({children}) => {
  const navigate = useNavigate()
  const [sidebarOpen, setSidebarOpen] = useSidebarClientState()

  const {mutate: signOut} = useSignOut()
  const {
    data: userCredit,
    isFetching: userCreditIsFetching,
    isLoading: userCreditIsLoading
  } = useGetUserCredit()

  const topMenuItems = [
    {text: 'Audience', icon: 'group', onClick: () => navigate('/audience-builder')},
    {text: 'Message', icon: 'message', onClick: () => navigate('/message-builder')},
    {text: 'Campaign', icon: 'campaign', onClick: () => navigate('/campaign-builder')},
    {text: 'Dashboard', icon: 'dashboard', onClick: () => navigate('/dashboard')}
  ]

  const bottomMenuItems = [
    {text: 'Profile', icon: 'person', onClick: () => navigate('/profile')},
    {text: 'Sign Out', icon: 'logout', onClick: () => signOut()}
  ]

  const SidebarItem = ({icon, text, onClick, open}) => {
    return (
      <div
        className='d-flex align-items-center clickable transition-fast t-color--accent'
        style={{padding: '1rem'}}
        onClick={onClick}
      >
        <span
          className='material-symbols-outlined'
          style={{
            marginRight: open ? '1rem' : '0',
            transition: `margin ${TRANSITION_DURATION}s ease`,
            willChange: 'margin'
          }}
          aria-hidden='true'
        >
          {icon}
        </span>
        <p
          style={{
            opacity: open ? 1 : 0,
            maxWidth: open ? `${DRAWER_WIDTH_OPEN}rem` : '0rem',
            margin: 0,
            transition: `opacity ${TRANSITION_DURATION}s ease, max-width ${TRANSITION_DURATION}s ease`,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            willChange: 'opacity, max-width',
            padding: 0
          }}
        >
          {text}
        </p>
      </div>
    )
  }

  return (
    <Box sx={{display: 'flex', margin: 0, height: '100%'}}>
      <Drawer
        variant='permanent'
        open={sidebarOpen}
        onMouseEnter={() => setSidebarOpen(true)}
        onMouseLeave={() => setSidebarOpen(false)}
        sx={{
          width: sidebarOpen ? `${DRAWER_WIDTH_OPEN}rem` : `${DRAWER_WIDTH_CLOSED}rem`,
          flexShrink: 0,
          transition: `width ${TRANSITION_DURATION}s ease`,
          willChange: 'width',
          '& .MuiDrawer-paper': {
            borderColor: sidebarOpen ? 'var(--accent)' : 'var(--bs-border-color)',
            width: sidebarOpen
              ? `${DRAWER_WIDTH_OPEN}rem`
              : `${DRAWER_WIDTH_CLOSED}rem`,
            transition: `width ${TRANSITION_DURATION}s ease, border-color 0.3s ease`,
            boxSizing: 'border-box',
            overflowX: 'hidden',
            overflowY: 'hidden',
            willChange: 'width, border-color'
          }
        }}
      >
        <div
          className='d-flex align-items-center justify-content-center'
          style={{padding: '1rem', height: '3.5rem'}}
        >
          {sidebarOpen ? (
            <PitchrIcon style={{height: '100%', width: 'auto'}} />
          ) : (
            <span
              className='material-symbols-outlined'
              aria-hidden='true'
            >
              {'menu'}
            </span>
          )}
        </div>

        <hr style={{margin: 0}} />
        <div
          className={`d-flex flex-column ${
            sidebarOpen ? 'align-items-start' : 'align-items-center'
          } justify-content-start`}
        >
          {topMenuItems.map((item, index) => (
            <SidebarItem
              icon={item.icon}
              onClick={item.onClick}
              text={item.text}
              open={sidebarOpen}
              key={index}
            />
          ))}
        </div>
        <div
          className={`d-flex flex-column ${
            sidebarOpen ? 'align-items-start' : 'align-items-center'
          } justify-content-end`}
          style={{marginTop: 'auto'}}
        >
          {bottomMenuItems.map((item, index) => (
            <SidebarItem
              icon={item.icon}
              onClick={item.onClick}
              text={item.text}
              open={sidebarOpen}
              key={index}
            />
          ))}

          {userCreditIsFetching || userCreditIsLoading ? (
            <div
              className='d-flex align-items-center clickable transition-fast t-color--accent'
              style={{padding: '1rem'}}
            >
              <Spinner size='sm' />
            </div>
          ) : (
            <SidebarItem
              icon={'paid'}
              text={`${userCredit?.available_credit}`}
              open={sidebarOpen}
              onClick={() => navigate('/profile')}
              key={'sidebar-credits'}
            />
          )}
        </div>
      </Drawer>
      <Box
        component='main'
        className='d-flex flex-column justify-content-start align-items-stretch'
        style={{
          flexGrow: 1,
          margin: 0
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
