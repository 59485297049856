import {atom} from 'recoil'
import {sessionStorageEffect} from './effects.js'

//ATOMS
export const UserAtom = atom({
  key: 'user',
  default: {
    thirdPartyProvisionsRequestProvider: '',
    thirdPartyProvisionsRequestScope: '',
    firstName: '',
    lastName: ''
  },
  effects: [sessionStorageEffect('user')]
})
