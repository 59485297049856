import {useNavigate} from 'react-router-dom'
import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery
} from '@tanstack/react-query'
import * as campaignsApi from '../../services/campaignsApi.js'
import {useToast} from '../../hooks/client/useToastsClientState.js'

export const useGetCampaignReport = ({params} = {}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['campaign-report', params],
    queryFn: () => campaignsApi.getCampaignReport({params}),
    staleTime: 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: false,
    onError: () => {
      addToast({
        header: 'Generating Campaign Report Failed.',
        body: 'Something went wrong when generating your campaign report. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const useGetCampaigns = ({params} = {}) => {
  const {addToast} = useToast()
  return useQuery({
    queryKey: ['campaigns', params],
    queryFn: () => campaignsApi.getCampaigns({params}),
    staleTime: 30 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    onError: () => {
      addToast({
        header: 'Fetching Campaigns Failed.',
        body: 'Something went wrong when retrieving your campaigns. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePostCampaign = () => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: ({payload}) => campaignsApi.postCampaign({payload}),
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: ['campaigns']})
      addToast({
        header: `Campaign "${data?.campaign_name}" Created!`,
        delay: 10000,
        body: (
          <span>
            Your can view your campaign{' '}
            <a
              href='#'
              onClick={() =>
                navigate(`/campaign-insights/?campaign_id=${data?.campaign_id}`)
              }
            >
              here
            </a>
            !
          </span>
        )
      })
    },
    onError: () => {
      addToast({
        header: 'Saving Campaign Failed.',
        body: 'Something went wrong when saving your campaign. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const usePatchCampaign = () => {
  const {addToast} = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) => campaignsApi.patchCampaign({params, payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['campaigns']})
      addToast({
        header: `Campaign Updated!`,
        body: 'Your campaign has been successfully updated!'
      })
    },
    onError: () => {
      addToast({
        header: 'Updating Campaign Failed.',
        body: 'Something went wrong when updating your campaign. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
export const useDeleteCampaign = () => {
  const queryClient = useQueryClient()
  const {addToast} = useToast()
  return useMutation({
    mutationFn: ({params}) => campaignsApi.deleteCampaign({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['campaigns']})
      addToast({
        header: `Campaign Deleted!`,
        body: 'Your campaign has been successfully deleted!'
      })
    },
    onError: () => {
      addToast({
        header: 'Deleting Campaign Failed.',
        body: 'Something went wrong when deleting your campaign. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const usePostCampaignEmailsSend = () => {
  const {addToast} = useToast()
  return useMutation({
    mutationFn: ({params, payload}) =>
      campaignsApi.postCampaignEmailsSend({params, payload}),
    onSuccess: () => {
      addToast({
        header: `Email Sent!`,
        body: 'The email has been successfully sent!'
      })
    },
    onError: () => {
      addToast({
        header: 'Sending Email Failed.',
        body: 'Something went wrong when sending the email. Please make sure your email account is connected.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const usePostCampaignValidate = ({payload} = {}) => {
  const {addToast} = useToast()
  const hasRealValues = Boolean(
    payload?.campaign_settings?.max_people_outreach > 0 &&
      payload?.campaign_settings?.max_people_outreach_per_company > 0 &&
      payload?.campaign_settings?.max_people_outreach_per_day > 0 &&
      payload?.audience?.companies_in_audience > 0 &&
      payload?.audience?.people_in_audience > 0 &&
      payload?.audience?.used_in_campaign !== undefined
  )

  return useQuery({
    mutationFn: ({payload}) => campaignsApi.postCampaignValidate({payload}),
    queryKey: ['campaignValidate', payload],
    queryFn: () => campaignsApi.postCampaignValidate({payload}),
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    enabled: hasRealValues,
    onError: () => {
      addToast({
        header: 'Validating Campaign Failed.',
        body: 'Something went wrong when validating your campaign. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const useGetCampaignsOutreachTrackerPeople = ({
  params = {},
  enabled = true
} = {}) => {
  const {addToast} = useToast()
  return useInfiniteQuery({
    queryKey: ['campaignsOutreachTrackerPeople', params],
    queryFn: ({pageParam = 1}) =>
      campaignsApi.getCampaignsOutreachTrackerPeople({
        params: {...params, page: pageParam}
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.has_next ? lastPage.page + 1 : undefined
    },
    staleTime: 30 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    enabled: !!enabled,
    onError: () => {
      addToast({
        header: 'Retrieving Campaign Outreach Information Failed.',
        body: 'Something went wrong when retrieving details about your campaign. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}

export const useGetCampaignsOutreachTrackerCompanies = ({
  params = {},
  enabled = true
}) => {
  const {addToast} = useToast()
  return useInfiniteQuery({
    queryKey: ['campaignsOutreachTrackerCompanies', params],
    queryFn: ({pageParam = 1}) =>
      campaignsApi.getCampaignsOutreachTrackerCompanies({
        params: {...params, page: pageParam}
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.has_next ? lastPage.page + 1 : undefined
    },
    staleTime: 30 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    enabled: !!enabled,
    onError: () => {
      addToast({
        header: 'Retrieving Campaign Outreach Information Failed.',
        body: 'Something went wrong when retrieving details about your campaign. Please wait a bit and try again.',
        className: 'errorToast',
        delay: 30000
      })
    }
  })
}
