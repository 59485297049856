import {Route, Routes, Navigate} from 'react-router-dom'

import {Dashboard} from './Dashboard.js'
import {AudienceBuilder} from './AudienceBuilder.js'
import {MessageBuilder} from './MessageBuilder.js'
import {CampaignBuilder} from './CampaignBuilder.js'
import {CampaignInsights} from './CampaignInsights.js'
import {Profile} from './Profile.js'
import {OAuthCallback} from './OAuthCallback.js'

import {Discovery} from './informational/Discovery.js'
import {Pricing} from './informational/Pricing.js'
import {Demo} from './informational/Demo.js'
import {ForgotPassword} from './informational/ForgotPassword.js'
import {SignIn} from './informational/SignIn.js'
import {SignUp} from './informational/SignUp.js'
import {Newsletter} from './informational/Newsletter.js'
import {Status} from './informational/Status.js'
import {
  OptOutPage,
  PrivacyPolicy,
  TermsAndConditions
} from './informational/MarkdownPages.js'
import {Contact} from './informational/Contact.js'
import {Data} from './informational/Data.js'

import {useFirebaseAuth} from '../../hooks/server/useUserServerState.js'
import {useGetKeepAlive} from '../../hooks/server/useUtilitiesServerState.js'

import {Spinner} from '../atoms/Spinner.js'

export const PrivateRoute = ({children}) => {
  const {currentUser, loading} = useFirebaseAuth()
  const {data: servicesStatus} = useGetKeepAlive()

  if (loading) {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <Spinner />
      </div>
    )
  }
  return currentUser ? children : <Navigate to='/sign-in' />
}

export const AppRoutes = () => (
  <>
    <Routes>
      <Route
        path='/'
        element={<Discovery />}
      />
      <Route
        path='/contact'
        element={<Contact />}
      />
      <Route
        path='/newsletter'
        element={<Newsletter />}
      />
      <Route
        path='/data'
        element={<Data />}
      />
      <Route
        path='/status'
        element={<Status />}
      />
      <Route
        path='/pricing'
        element={<Pricing />}
      />
      <Route
        path='/demo'
        element={<Demo />}
      />
      <Route
        path='/opt-out'
        element={<OptOutPage />}
      />
      <Route
        path='/privacy-policy'
        element={<PrivacyPolicy />}
      />
      <Route
        path='/terms-and-conditions'
        element={<TermsAndConditions />}
      />
      <Route
        path='/sign-in'
        element={<SignIn />}
      />
      <Route
        path='/sign-up'
        element={<SignUp />}
      />
      <Route
        path='/forgot-password'
        element={<ForgotPassword />}
      />
      <Route
        path='/oauth-callback'
        element={
          <PrivateRoute>
            <OAuthCallback />
          </PrivateRoute>
        }
      />
      <Route
        path='/message-builder'
        element={
          <PrivateRoute>
            <MessageBuilder />
          </PrivateRoute>
        }
      />
      <Route
        path='/audience-builder'
        element={
          <PrivateRoute>
            <AudienceBuilder />
          </PrivateRoute>
        }
      />
      <Route
        path='/campaign-builder'
        element={
          <PrivateRoute>
            <CampaignBuilder />
          </PrivateRoute>
        }
      />
      <Route
        path='/campaign-insights'
        element={
          <PrivateRoute>
            <CampaignInsights />
          </PrivateRoute>
        }
      />
      <Route
        path='/dashboard'
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path='/profile'
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path='*'
        element={<Navigate to='/' />}
      />
    </Routes>
  </>
)
