import {Spinner as BootstrapSpinner} from 'react-bootstrap'

export const Spinner = ({
  className = 'd-spinner',
  animation = 'grow',
  size = 'sm',
  as = 'span',
  style = {}
}) => {
  return (
    <BootstrapSpinner
      className={className}
      animation={animation}
      as={as}
      style={style}
      size={size}
    />
  )
}
