import {Form, Accordion, ListGroup} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'

const createFormChecks = (item) =>
  item.values.map((value, index) => (
    <Form.Check
      key={`${item.name}-${index}`}
      label={item.labels[index]}
      type={item.type}
      id={`${item.name}-${value.replace(/,/g, '-')}`}
      name={item.name}
      value={value}
    />
  ))

export const CampaignSearchForm = ({formRef, onFormChange, listOfItems}) => {
  return (
    <Accordion
      flush
      className='searchForm'
      alwaysOpen
    >
      <Form
        ref={formRef}
        onChange={onFormChange}
        onReset={onFormChange}
      >
        {listOfItems.map((item, index) => (
          <Accordion.Item
            key={index}
            eventKey={index.toString()}
          >
            <Accordion.Header>{item.header}</Accordion.Header>
            <Accordion.Body>
              <Form.Group>{createFormChecks(item)}</Form.Group>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Form>
    </Accordion>
  )
}

export const CampaignSearchFormFooter = ({totalResults, isLoading}) => {
  return (
    <ListGroup
      className='searchFormFooter'
      horizontal
    >
      <ListGroup.Item>
        {isLoading ? (
          <Spinner
            size='sm'
            animation='grow'
          />
        ) : (
          <h6 className='d-text__font--subheading d-text__placement--center color--s-dark'>{`Total Results: ${
            totalResults || ''
          }`}</h6>
        )}
      </ListGroup.Item>
    </ListGroup>
  )
}
