import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  Form,
  Row,
  Stack,
  Col,
  FloatingLabel,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import {Paper} from '@mui/material'

import {useUserAtom} from '../../hooks/client/useUserClientState.js'
import * as UserServerState from '../../hooks/server/useUserServerState.js'
import {useGetClientConfig} from '../../hooks/server/useUtilitiesServerState.js'

import {SidebarTemplate} from '../templates/SidebarTemplate.js'

import {Button} from '../atoms/Button.js'
import {Spinner} from '../atoms/Spinner.js'

import {ReactComponent as MicrosoftSignInWithIcon} from '../atoms/assets/MicrosoftSignInWith.svg'
import {ReactComponent as GoogleContinueWithIcon} from '../atoms/assets/GoogleContinueWith.svg'
import {ReactComponent as OutlookIcon} from '../atoms/assets/Outlook.svg'
import GmailIcon from '../atoms/assets/Gmail.png'

export const getPlanLabel = (userCredit) => {
  if (userCredit?.refill_amount === 350) return 'Starter'
  if (userCredit?.refill_amount === 1300) return 'Standard'
  if (userCredit?.refill_amount === 3200) return 'Premium'
  return 'Custom'
}

export const getConnectedEmail = (userTPP) => {
  if (userTPP?.provider === 'google') return 'Google'
  if (userTPP?.provider === 'microsoft') return 'Microsoft'
  return 'Please Connect an Account'
}

const renderTooltip = (props, text) => (
  <Tooltip
    id='button-tooltip'
    {...props}
  >
    {text}
  </Tooltip>
)

export const Profile = () => {
  const navigate = useNavigate()

  //GLOBAL UI STATE
  const [user, setUser, setUserKey] = useUserAtom()

  //LOCAL UI STATE
  const [showConnectionModel, setShowConnectionModal] = useState(false)

  //SERVER STATE
  const {data: clientConfig} = useGetClientConfig()
  const {mutate: initiateGoogleOAuth} = UserServerState.useInitiateGoogleOAuth()
  const {mutate: initiateMicrosoftOAuth} = UserServerState.useInitiateMicrosoftOAuth()
  const {data: userTPP, isFetching: userTPPIsFetching} =
    UserServerState.useGetUserThirdPartyProvisions()

  const {data: firebaseUserId, isLoading: firebaseUserIdIsLoading} =
    UserServerState.useFirebaseUserId()
  const {
    data: userIdentity,
    isFetching: userIdentityIsFetching,
    isLoading: userIdentityIsLoading
  } = UserServerState.useGetUserIdentity({
    params: {firebase_user_id: firebaseUserId},
    enabled: !firebaseUserIdIsLoading
  })

  const {mutate: patchUserProfile, isPending: patchUserProfileIsPending} =
    UserServerState.usePatchUserProfile()
  const {
    data: userProfile,
    isFetching: userProfileIsFetching,
    isLoading: userProfileIsLoading
  } = UserServerState.useGetUserProfile()

  const {
    data: userCredit,
    isFetching: userCreditIsFetching,
    isLoading: userCreditIsLoading
  } = UserServerState.useGetUserCredit()

  //HOOKS
  const handleConnectAccount = (account_type) => {
    if (account_type === 'google') {
      setUser((user) => ({
        ...user,
        thirdPartyProvisionsRequestProvider: 'google',
        thirdPartyProvisionsRequestScope:
          'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly'
      }))
      initiateGoogleOAuth({googleClientId: clientConfig.googleClientId})
    } else if (account_type === 'microsoft') {
      setUser((user) => ({
        ...user,
        thirdPartyProvisionsRequestProvider: 'microsoft',
        thirdPartyProvisionsRequestScope:
          'https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/Mail.ReadWrite offline_access'
      }))
      initiateMicrosoftOAuth({microsoftClientId: clientConfig.microsoftClientId})
    }
  }

  useEffect(() => {
    setUser((user) => ({
      ...user,
      firstName: userProfile?.first_name,
      lastName: userProfile?.last_name
    }))
  }, [userProfile, setUser])

  const refillDate = new Date(userCredit?.refill_date)
  const options = {day: 'numeric', month: 'long'}
  const formattedDate = refillDate.toLocaleDateString(undefined, options)

  return (
    <SidebarTemplate>
      <Modal
        show={showConnectionModel}
        onHide={() => setShowConnectionModal(false)}
        size='md'
      >
        <Modal.Header style={{alignSelf: 'center'}}>Connect Email Account</Modal.Header>
        <Modal.Body>
          <Stack
            direction='horizontal'
            className='d-flex justify-content-around'
          >
            <GoogleContinueWithIcon
              className='clickable'
              style={{height: '3rem'}}
              onClick={() => {
                handleConnectAccount('google')
              }}
            />
            <MicrosoftSignInWithIcon
              className='clickable'
              style={{height: '2.5rem'}}
              onClick={() => {
                handleConnectAccount('microsoft')
              }}
            />
          </Stack>
        </Modal.Body>
      </Modal>

      <Paper
        className='d-flex flex-column align-items-stretch background--p-light'
        variant='outlined'
        style={{padding: '1rem', borderRadius: '1rem', margin: '2rem'}}
      >
        <h5
          className='d-text__font--heading color--p-dark'
          style={{alignSelf: 'start'}}
        >
          Account Information
        </h5>
        <hr style={{marginTop: 0}} />

        <Form
          className='d-form'
          style={{width: '100%'}}
        >
          <Form.Group as={Row}>
            <Form.Group
              as={Col}
              xs={5}
              style={{paddingRight: '2rem'}}
            >
              <Form.Label as={Row}>User Account</Form.Label>
              <Form.Text
                muted
                as={Row}
              >
                Used to sign in, for email receipts & product updates
              </Form.Text>
            </Form.Group>
            <InputGroup as={Col}>
              <FloatingLabel label='Signed in as'>
                <Form.Control
                  type='text'
                  value={userIdentity?.email ?? ''}
                  style={{borderRight: 'none'}}
                  readOnly
                />
              </FloatingLabel>
              <InputGroup.Text>
                {(userIdentityIsFetching || userIdentityIsLoading) && (
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                )}
                {!userIdentityIsFetching && !userIdentityIsLoading && (
                  <span className='material-symbols-outlined '>{'person'}</span>
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group as={Row}>
            <Col
              xs={5}
              style={{paddingRight: '2rem'}}
            >
              <Form.Label as={Row}>Name</Form.Label>
              <Form.Text
                muted
                as={Row}
              >
                Required for personalized message generation
              </Form.Text>
            </Col>
            <InputGroup
              as={Col}
              style={{marginRight: '0.75rem'}}
            >
              <FloatingLabel label='First Name'>
                <Form.Control
                  type='text'
                  value={user?.firstName ?? ''}
                  onChange={(e) => setUserKey('firstName', e.target.value)}
                  onBlur={() => {
                    if (user?.firstName !== userProfile?.first_name) {
                      patchUserProfile({
                        payload: {
                          ...userProfile,
                          first_name: user?.firstName
                        }
                      })
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                  style={{borderRight: 'none'}}
                  readOnly={
                    userProfileIsFetching ||
                    userProfileIsLoading ||
                    patchUserProfileIsPending
                  }
                />
              </FloatingLabel>
              <InputGroup.Text>
                {(userProfileIsFetching ||
                  userProfileIsLoading ||
                  patchUserProfileIsPending) && (
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
            <InputGroup as={Col}>
              <FloatingLabel label='Last Name'>
                <Form.Control
                  type='text'
                  value={user?.lastName ?? ''}
                  onChange={(e) => setUserKey('lastName', e.target.value)}
                  onBlur={() => {
                    if (user?.lastName !== userProfile?.last_name) {
                      patchUserProfile({
                        payload: {...userProfile, last_name: user?.lastName}
                      })
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                  style={{borderRight: 'none'}}
                  readOnly={
                    userProfileIsFetching ||
                    userProfileIsLoading ||
                    patchUserProfileIsPending
                  }
                />
              </FloatingLabel>
              <InputGroup.Text>
                {(userProfileIsFetching ||
                  userProfileIsLoading ||
                  patchUserProfileIsPending) && (
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <hr />

          <Form.Group as={Row}>
            <Col
              xs={5}
              style={{paddingRight: '2rem'}}
            >
              <Form.Label as={Row}>Email</Form.Label>
              <Form.Text
                muted
                as={Row}
              >
                Account to use in campaigns for sending out emails
              </Form.Text>
            </Col>
            <InputGroup as={Col}>
              <FloatingLabel label='Connected Account'>
                <Form.Control
                  type='text'
                  value={userTPPIsFetching ? '' : getConnectedEmail(userTPP) ?? ''}
                  style={{borderRight: 'none'}}
                  readOnly
                />
              </FloatingLabel>
              <InputGroup.Text>
                {userTPPIsFetching && (
                  <Spinner
                    size='sm'
                    animation='grow'
                    style={{marginRight: '0.5em'}}
                  />
                )}
                {!userTPPIsFetching && !userTPP?.is_valid && (
                  <OverlayTrigger
                    key='review-overlay'
                    placement='top'
                    overlay={(props) =>
                      renderTooltip(
                        props,
                        'Cannot connect to your account. Please connect your account again.'
                      )
                    }
                  >
                    <span
                      className='material-symbols-outlined'
                      style={{
                        color: 'red',
                        marginRight: '0.5rem',
                        fontVariationSettings:
                          '"FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24'
                      }}
                    >
                      {'sync_problem'}
                    </span>
                  </OverlayTrigger>
                )}
                {!userTPPIsFetching && userTPP?.provider === 'google' && (
                  <img
                    src={GmailIcon}
                    alt='Gmail Icon'
                    style={{maxWidth: '1.5rem', maxHeight: '1.5rem'}}
                  />
                )}
                {!userTPPIsFetching && userTPP?.provider === 'microsoft' && (
                  <OutlookIcon style={{maxWidth: '1.75rem', maxHeight: '1.75rem'}} />
                )}
              </InputGroup.Text>
            </InputGroup>
            <Col className='d-flex justify-content-center align-items-center'>
              <Button
                children={
                  <>
                    {'Connect Email'}
                    <span
                      className='material-symbols-outlined'
                      style={{marginLeft: '0.5rem'}}
                    >
                      {'link'}
                    </span>
                  </>
                }
                onClick={() => setShowConnectionModal(true)}
              />
            </Col>
          </Form.Group>

          <hr />

          <Form.Group as={Row}>
            <Col
              xs={5}
              style={{paddingRight: '2rem'}}
            >
              <Form.Label as={Row}>Subscription</Form.Label>
              <Form.Text
                muted
                as={Row}
              >
                <span style={{padding: 0}}>
                  {'Need more credits? Upgrade your plan tier '}
                  <span
                    className='color--accent clickable'
                    onClick={() => {
                      navigate('/contact#contact-us')
                    }}
                  >
                    here
                  </span>
                </span>
              </Form.Text>
            </Col>

            <InputGroup
              as={Col}
              style={{marginRight: '0.75rem'}}
            >
              <FloatingLabel label='Current Plan'>
                <Form.Control
                  type='text'
                  value={
                    userCreditIsFetching || userCreditIsLoading
                      ? ''
                      : getPlanLabel(userCredit) ?? ''
                  }
                  style={{borderRight: 'none'}}
                  readOnly
                />
              </FloatingLabel>
              <InputGroup.Text>
                {(userCreditIsFetching || userCreditIsLoading) && (
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                )}
                {!userCreditIsFetching && !userCreditIsLoading && (
                  <span className='material-symbols-outlined '>{'bolt'}</span>
                )}
              </InputGroup.Text>
            </InputGroup>

            <InputGroup as={Col}>
              <FloatingLabel
                label={
                  userCreditIsFetching || userCreditIsLoading
                    ? ''
                    : userCredit?.refill_amount > 0
                    ? `Refill on ${formattedDate}`
                    : `Expires on ${formattedDate}`
                }
              >
                <Form.Control
                  type='text'
                  value={userCredit?.refill_amount ?? ''}
                  style={{borderRight: 'none'}}
                  readOnly
                />
              </FloatingLabel>
              <InputGroup.Text>
                {(userCreditIsFetching || userCreditIsLoading) && (
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                )}
                {!userCreditIsFetching && !userCreditIsLoading && (
                  <span className='material-symbols-outlined '>{'update'}</span>
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Form>
      </Paper>
    </SidebarTemplate>
  )
}
