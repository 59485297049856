import {useRef, useState} from 'react'

//ATOMS
import {
  Form,
  Row,
  InputGroup,
  Col,
  Container,
  Nav,
  Stack,
  Collapse
} from 'react-bootstrap'
import {Button} from '../atoms/Button.js'

//MOLECULES
import {
  CampaignSearchForm,
  CampaignSearchFormFooter
} from '../molecules/CampaignSearchForm.js'
import {
  PersonSearchResults,
  CompanySearchResults
} from '../molecules/CampaignSearchResults.js'

//ORGANISMS
import {
  SettingsDetails,
  ConversationTimeline
} from '../organisms/CampaignBuilderInput.js'

//HOOKS
import * as CampaignSS from '../../hooks/server/useCampaignsServerState.js'
import {useCampaignAtom} from '../../hooks/client/useCampaignsClientState.js'
import {MessageModal} from './MessageModals.js'

//UTILS
import {
  checkMaxPeopleOutreach,
  checkMaxPeopleOutreachPerDay,
  checkMaxPeopleOutreachPerCompany,
  calculatePeoplePerCompany,
  calculateLogDiscrepancyRatio,
  calculatePeopleToReach,
  calculateCompaniesToReach,
  checkCompaniesReach,
  checkPeopleReach,
  calculateMaxPeopleOutreach
} from '../../utils/calculations.js'
import {getCheckedValues} from '../../utils/helpers.js'
import {isTruthy} from '../../utils/hooks.js'

export const campaignStatusMapping = {
  needs_review: {
    failed_retrieving_valid_access_token: {
      text: 'Email account required for email outreach has been disconnected. Please reconnect your email in the account page.',
      icon: 'mail_lock',
      color: 'red'
    },
    failed_due_to_insufficient_credits: {
      text: 'Insufficient credits to proceed with the campaign.',
      icon: 'money_off',
      color: 'red'
    },
    default: {
      text: 'Something went wrong with the execution of your campaign. We are looking into it. Sorry for the inconvenience.',
      icon: 'sync_problem',
      color: 'red'
    }
  },
  in_progress: {
    text: 'Campaign is in progress ',
    icon: 'play_circle',
    color: 'orange'
  },
  paused: {
    text: 'Campaign is paused',
    icon: 'pause',
    color: 'gray'
  },
  completed: {
    text: 'Campaign has been completed',
    icon: 'check_circle',
    color: 'green'
  }
}

const getCampaignStatusDetails = (status, reason) => {
  const statusDetails = campaignStatusMapping[status]

  if (status === 'needs_review') {
    return (
      statusDetails[reason] ||
      statusDetails.default || {
        text: 'Unknown status.',
        icon: 'help_outline',
        color: 'black'
      }
    )
  }

  return (
    statusDetails || {
      text: 'Unknown status.',
      icon: 'help_outline',
      color: 'black'
    }
  )
}

export const StatusTab = ({campaign}) => {
  return (
    <Form
      className='d-form'
      style={{width: '100%', overflow: 'hidden'}}
    >
      <Form.Group as={Row}>
        <Form.Group
          as={Col}
          xs={5}
          style={{paddingRight: '2rem'}}
        >
          <Form.Label as={Row}>Campaign Status</Form.Label>
        </Form.Group>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='d-flex align-items-center'>
            {
              getCampaignStatusDetails(
                campaign?.campaignStatus,
                campaign?.campaignReviewReason
              )?.text
            }

            <span
              className='material-symbols-outlined'
              style={{
                fontSize: '1.5rem',
                color: getCampaignStatusDetails(
                  campaign?.campaignStatus,
                  campaign?.campaignReviewReason
                )?.color,
                marginLeft: '0.25rem'
              }}
            >
              {
                getCampaignStatusDetails(
                  campaign?.campaignStatus,
                  campaign?.campaignReviewReason
                )?.icon
              }
            </span>
          </div>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Group
          as={Col}
          xs={5}
          style={{paddingRight: '2rem'}}
        >
          <Form.Label as={Row}>Outreach</Form.Label>
          {/* <Form.Text
            muted
            as={Row}
          >
            An active campaign means blabla
          </Form.Text> */}
        </Form.Group>
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='d-flex align-items-center'>
            {campaign?.messagesGenerated ? (
              <p
                className='d-flex align-items-center'
                style={{margin: 0}}
              >
                {campaign?.campaignStatus === 'completed'
                  ? 'Campaign Completed'
                  : 'Ready for next outreach!'}
                <span
                  className={'material-symbols-outlined'}
                  style={{
                    marginLeft: '0.25rem',
                    color: 'var(--success)'
                  }}
                >
                  {'verified'}
                </span>
              </p>
            ) : (
              <p
                className='d-flex align-items-center'
                style={{margin: 0}}
              >
                Preparing next outreach...
                <span
                  className={'material-symbols-outlined color--s-dark'}
                  style={{
                    marginLeft: '0.25rem'
                  }}
                >
                  {'find_replace'}
                </span>
              </p>
            )}
          </div>
        </Col>
      </Form.Group>
    </Form>
  )
}

export const SettingsTab = ({
  campaign,
  campaignServerState,
  setCampaignKey,
  audienceMetaData,
  audienceOnClick,
  onInputInstructionsUpdate,
  onOutreachUpdate,
  campaignIsUpdating,
  messageInstructions,
  messageInstructionsFetchStatus
}) => {
  const {campaignStepsToResponse} = useCampaignAtom()

  const maxPeopleOutreach = calculateMaxPeopleOutreach({
    maxOutreachMessages: campaign?.maxOutreachMessages,
    maxOutreachPerPerson: campaign?.steps?.length
  })
  const peoplePerCompany = calculatePeoplePerCompany({
    peopleInAudience: audienceMetaData?.peopleInAudience,
    companiesInAudience: audienceMetaData?.companiesInAudience
  })
  const companiesToReach = calculateCompaniesToReach({
    maxOutreachPeoplePerCompany: campaign?.maxOutreachPeoplePerCompany,
    maxPeopleOutreach,
    peoplePerCompany,
    companiesInAudience: audienceMetaData?.companiesInAudience
  })
  const peopleToReach = calculatePeopleToReach({
    companiesToReach,
    maxOutreachPeoplePerCompany: campaign?.maxOutreachPeoplePerCompany,
    maxPeopleOutreach,
    peopleInAudience: audienceMetaData?.peopleInAudience
  })
  const maxPeopleOutreachError = checkMaxPeopleOutreach({
    maxPeopleOutreach,
    maxOutreachMessagesPerDay: campaign?.maxOutreachMessagesPerDay,
    maxOutreachPeoplePerCompany: campaign?.maxOutreachPeoplePerCompany
  })
  const maxPeopleOutreachPerDayError = checkMaxPeopleOutreachPerDay({
    maxOutreachMessagesPerDay: campaign?.maxOutreachMessagesPerDay
  })
  const maxPeopleOutreachPerCompanyError = checkMaxPeopleOutreachPerCompany({
    maxOutreachPeoplePerCompany: campaign?.maxOutreachPeoplePerCompany
  })
  const companiesToReachError = checkCompaniesReach({
    companiesToReach,
    companiesInAudience: audienceMetaData?.companiesInAudience
  })
  const logDiscrepancyRatio = calculateLogDiscrepancyRatio({
    maxOutreachPeoplePerCompany: campaign?.maxOutreachPeoplePerCompany,
    peoplePerCompany
  })
  const peopleToReachError = checkPeopleReach({
    peopleToReach,
    peopleInAudience: audienceMetaData?.peopleInAudience,
    logDiscrepancyRatio
  })

  const outreachInputIsValid =
    isTruthy(campaign?.maxOutreachMessages) &&
    isTruthy(campaign?.maxOutreachMessagesPerDay) &&
    isTruthy(campaign?.maxOutreachPeoplePerCompany) &&
    !isTruthy(maxPeopleOutreachError) &&
    !isTruthy(maxPeopleOutreachPerDayError) &&
    !isTruthy(maxPeopleOutreachPerCompanyError)

  const stepsAreValid =
    isTruthy(campaign?.steps) &&
    campaign?.steps?.every(
      (step) => isTruthy(step?.messageInstructionsId) && isTruthy(step?.index)
    )

  const outreachIsValid =
    outreachInputIsValid &&
    !isTruthy(companiesToReachError) &&
    !isTruthy(peopleToReachError) &&
    stepsAreValid

  const outreachHasChanged =
    campaignServerState?.max_people_outreach !== campaign?.maxOutreachMessages ||
    campaignServerState?.max_people_outreach_per_day !==
      campaign?.maxOutreachMessagesPerDay ||
    campaignServerState?.max_people_outreach_per_company !==
      campaign?.maxOutreachPeoplePerCompany ||
    JSON.stringify(campaignStepsToResponse(campaign?.steps)) !==
      JSON.stringify(campaignServerState?.steps)

  return (
    <Container fluid>
      <Form
        className='d-form'
        style={{width: '100%'}}
      >
        <Form.Group as={Row}>
          <Form.Group
            as={Col}
            xs={5}
            style={{paddingRight: '2rem'}}
          >
            <Form.Label as={Row}>Audience Used</Form.Label>
            <Form.Text
              muted
              as={Row}
            >
              Changing the audience used for a campaign is not supported.
            </Form.Text>
          </Form.Group>
          <Col className='d-flex align-items-center justify-content-center'>
            <Form.Text
              onClick={audienceOnClick}
              className='clickable transition-fast t-color--accent'
            >
              <span
                className='d-flex align-items-center'
                style={{fontSize: '1.22rem'}}
              >
                <span
                  className='material-symbols-outlined'
                  style={{marginRight: '0.25rem', fontSize: '1.728rem'}}
                >
                  group
                </span>
                {audienceMetaData?.label}
              </span>
            </Form.Text>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Group
            as={Col}
            xs={'auto'}
            style={{marginRight: '2rem'}}
          >
            <Form.Label as={Row}>Ranking Instructions</Form.Label>
            <Form.Text
              muted
              as={Row}
            >
              Rank people based on...
            </Form.Text>
          </Form.Group>
          <InputGroup as={Col}>
            <Collapse
              in={
                campaignServerState?.campaign_sorting_instructions !==
                campaign?.campaignSortingInstructions
              }
              dimension={'width'}
            >
              <div>
                <Button
                  children='Update'
                  style={{marginRight: '1rem'}}
                  onClick={onInputInstructionsUpdate}
                  isLoading={campaignIsUpdating}
                />
              </div>
            </Collapse>
            <Form.Control
              type='text'
              placeholder='how likely they are a decision maker'
              name='campaignSortingInstructions'
              value={campaign?.campaignSortingInstructions || ''}
              onChange={(e) => {
                setCampaignKey('campaignSortingInstructions', e?.target?.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.target.blur()
                }
              }}
            />
          </InputGroup>
        </Form.Group>
      </Form>

      <hr />

      <h6
        style={{textAlign: 'center', marginBottom: '0.5rem'}}
        className='d-text__font--heading color--p-dark'
      >
        Outreach Preferences
      </h6>

      <ConversationTimeline
        conversationState={campaign?.steps}
        setConversationState={(e) => setCampaignKey('steps', e)}
        messageInstructions={messageInstructions}
        messageInstructionsIsLoading={messageInstructionsFetchStatus === 'fetching'}
        messageInstructionsIsFetching={messageInstructionsFetchStatus === 'fetching'}
        stepsAreValid={stepsAreValid}
      />

      <SettingsDetails
        campaignAtom={campaign}
        setCampaignAtomKey={setCampaignKey}
        maxPeopleOutreachError={maxPeopleOutreachError}
        maxPeopleOutreachPerDayError={maxPeopleOutreachPerDayError}
        maxPeopleOutreachPerCompanyError={maxPeopleOutreachPerCompanyError}
        outreachInputIsValid={outreachInputIsValid}
        companiesToReach={companiesToReach}
        peopleToReach={peopleToReach}
        companiesToReachError={companiesToReachError}
        peopleToReachError={peopleToReachError}
        peopleInAudience={audienceMetaData?.peopleInAudience}
        companiesInAudience={audienceMetaData?.companiesInAudience}
      />
      <Collapse in={outreachIsValid && outreachHasChanged}>
        <div>
          <Button
            children='Apply New Outreach Preference'
            style={{margin: '1rem auto 0 auto'}}
            onClick={onOutreachUpdate}
            isLoading={campaignIsUpdating}
          />
        </div>
      </Collapse>
    </Container>
  )
}

export const InsightsTab = ({campaign}) => {
  const [activePerson, setActivePerson] = useState(null)

  const [activeInsightsTab, setActiveInsightsTab] = useState('companies')
  const companySearchFormRef = useRef(null)
  const companySearchFormDebounceTimeoutRef = useRef(null)
  const [companySearchIsDebouncing, setCompanySearchIsDebouncing] = useState(false)
  const [companyOutreachParams, setCompanyOutreachParams] = useState(null)

  const personSearchFormRef = useRef(null)
  const personSearchFormDebounceTimeoutRef = useRef(null)
  const [personSearchIsDebouncing, setPersonSearchIsDebouncing] = useState(false)
  const [personOutreachParams, setPersonOutreachParams] = useState(null)

  const {
    data: companyOutreachTracking,
    fetchNextPage: companyOutreachTrackingFetchNextPage,
    hasNextPage: companyOutreachTrackingHasNextPage,
    fetchStatus: companyOutreachTrackingFetchStatus
  } = CampaignSS.useGetCampaignsOutreachTrackerCompanies({
    params: companyOutreachParams || {
      campaign_id: campaign?.campaignId,
      per_page: 10
    },
    enabled: isTruthy(campaign?.campaignId)
  })
  const {
    data: personOutreachTracking,
    fetchNextPage: personOutreachTrackingFetchNextPage,
    hasNextPage: personOutreachTrackingHasNextPage,
    fetchStatus: personOutreachTrackingFetchStatus
  } = CampaignSS.useGetCampaignsOutreachTrackerPeople({
    params: personOutreachParams || {campaign_id: campaign?.campaignId, per_page: 10},
    enabled: isTruthy(campaign?.campaignId)
  })

  const personSearchFormItems = [
    {
      header: 'Outreach Status',
      type: 'checkbox',
      name: 'person-outreach-status',
      values: [
        'awaiting_message,awaiting_outreach',
        'skipped',
        'sent,received_no_response',
        'received_bounce',
        'received_response_from_recipient'
      ],
      labels: ['In Queue', 'Skipped', 'Sent', 'Bounce', 'Response']
    },
    {
      header: 'Skip Reason',
      type: 'checkbox',
      name: 'person-outreach-skip-reason',
      values: [
        'missing_person_linkedin_id,missing_person_summary,missing_company_linkedin_id,missing_company_summary',
        'missing_person_email',
        'found_better_candidate,person_exists_for_multiple_companies',
        'failed_sending_email'
      ],
      labels: [
        'Insufficient Information',
        'Missing Email',
        'Found Better Candidate',
        'Failed Sending Email'
      ]
    }
  ]

  const companySearchFormItems = [
    {
      header: 'Outreach Status',
      type: 'checkbox',
      name: 'company-outreach-status',
      values: [
        'awaiting_people,awaiting_outreach',
        'skipped',
        'sent,received_no_response',
        'received_bounce',
        'received_response_from_recipient'
      ],
      labels: ['In Queue', 'Skipped', 'Sent', 'Bounce', 'Response']
    },
    {
      header: 'Skip Reason',
      type: 'checkbox',
      name: 'company-outreach-skip-reason',
      values: [
        'missing_company_summary,missing_company_name,missing_company_linkedin_id',
        'missing_relevant_people'
      ],
      labels: ['Insufficient Information', 'Missing relevant people']
    }
  ]

  const handleCompaniesSearchFormChange = () => {
    const form = companySearchFormRef.current

    let companySearchSetting = {
      campaign_id: campaign?.campaignId,
      per_page: 10
    }

    const outreachStatus = getCheckedValues(form, 'company-outreach-status')
    if (outreachStatus.length > 0) {
      companySearchSetting.outreach_status = outreachStatus
    }

    const skipReason = getCheckedValues(form, 'company-outreach-skip-reason')
    if (skipReason.length > 0) {
      companySearchSetting.skip_reason = skipReason
    }

    clearTimeout(companySearchFormDebounceTimeoutRef.current)
    setCompanySearchIsDebouncing(true)
    companySearchFormDebounceTimeoutRef.current = setTimeout(() => {
      setCompanyOutreachParams({...companySearchSetting})
      setCompanySearchIsDebouncing(false)
    }, 2000)
  }

  const handlePersonSearchFormChange = () => {
    const form = personSearchFormRef.current

    let personSearchSetting = {
      campaign_id: campaign?.campaignId,
      per_page: 10
    }

    const outreachStatus = getCheckedValues(form, 'person-outreach-status')
    if (outreachStatus.length > 0) {
      personSearchSetting.outreach_status = outreachStatus
    }

    const skipReason = getCheckedValues(form, 'person-outreach-skip-reason')
    if (skipReason.length > 0) {
      personSearchSetting.skip_reason = skipReason
    }

    clearTimeout(personSearchFormDebounceTimeoutRef.current)
    setPersonSearchIsDebouncing(true)
    personSearchFormDebounceTimeoutRef.current = setTimeout(() => {
      setPersonOutreachParams({...personSearchSetting})
      setPersonSearchIsDebouncing(false)
    }, 2000)
  }

  return (
    <Container
      fluid
      className='searchContainer'
    >
      <MessageModal
        show={isTruthy(activePerson)}
        onHide={() => setActivePerson(null)}
        label={activePerson?.email_address || ''}
        emailSubject={activePerson?.message_header || ''}
        emailBody={activePerson?.message_body || ''}
        updatedAt={activePerson?.updated_at || ''}
      />
      <Nav
        variant='tabs'
        activeKey={activeInsightsTab}
        onSelect={(k) => {
          setActiveInsightsTab(k)
          if (personSearchFormRef.current) {
            personSearchFormRef.current.reset()
          }
          if (companySearchFormRef.current) {
            companySearchFormRef.current.reset()
          }
        }}
      >
        <Nav.Item>
          <Nav.Link eventKey='companies'>
            <Stack
              direction='horizontal'
              gap={2}
            >
              Companies
              <span
                className={
                  activeInsightsTab === 'companies'
                    ? 'material-symbols-outlined color--accent'
                    : 'material-symbols-outlined color--s-dark'
                }
              >
                {'source_environment'}
              </span>
            </Stack>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey='people'>
            <Stack
              direction='horizontal'
              gap={2}
            >
              People
              <span
                className={
                  activeInsightsTab === 'people'
                    ? 'material-symbols-outlined color--accent'
                    : 'material-symbols-outlined color--s-dark'
                }
                style={{fontSize: '2rem'}}
              >
                {'groups'}
              </span>
            </Stack>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {activeInsightsTab === 'companies' && (
        <Row>
          <Col
            className='searchCol'
            xs={3}
          >
            <CampaignSearchForm
              formRef={companySearchFormRef}
              onFormChange={handleCompaniesSearchFormChange}
              listOfItems={companySearchFormItems}
            />
            <CampaignSearchFormFooter
              totalResults={companyOutreachTracking?.pages[0]?.total}
              isLoading={companyOutreachTrackingFetchStatus === 'fetching'}
            />
          </Col>
          <Col className='resultCol'>
            <CompanySearchResults
              companyOutreachTracking={
                companyOutreachTracking?.pages?.flatMap((page) => page?.items) || []
              }
              isLoading={companyOutreachTrackingFetchStatus === 'fetching'}
              isFetching={
                companyOutreachTrackingFetchStatus === 'fetching' ||
                companySearchIsDebouncing
              }
              hasNextPage={companyOutreachTrackingHasNextPage}
              fetchNextPage={companyOutreachTrackingFetchNextPage}
            />
          </Col>
        </Row>
      )}
      {activeInsightsTab === 'people' && (
        <Row>
          <Col
            className='searchCol'
            xs={3}
          >
            <CampaignSearchForm
              formRef={personSearchFormRef}
              onFormChange={handlePersonSearchFormChange}
              listOfItems={personSearchFormItems}
            />
            <CampaignSearchFormFooter
              totalResults={personOutreachTracking?.pages[0]?.total}
              isLoading={personOutreachTrackingFetchStatus === 'fetching'}
            />
          </Col>
          <Col className='resultCol'>
            <PersonSearchResults
              personOutreachTracking={
                personOutreachTracking?.pages.flatMap((page) => page?.items) || []
              }
              isLoading={personOutreachTrackingFetchStatus === 'fetching'}
              isFetching={
                personOutreachTrackingFetchStatus === 'fetching' ||
                personSearchIsDebouncing
              }
              hasNextPage={personOutreachTrackingHasNextPage}
              fetchNextPage={personOutreachTrackingFetchNextPage}
              setActivePerson={setActivePerson}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}
