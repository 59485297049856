import {Spinner} from '../atoms/Spinner.js'
import {TabList} from '@mui/lab'
import {Tab, Box} from '@mui/material'

export const DynamicTabList = ({tabs, activeTab, setActiveTab}) => {
  return (
    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
      <TabList
        value={activeTab}
        onChange={(e, v) => {
          setActiveTab(v)
        }}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'var(--accent)'
          },
          '& .MuiTab-root': {
            color: 'var(--text-color, rgba(0, 0, 0, 0.6))',
            transition: 'color 0.3s'
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'var(--accent)'
          }
        }}
      >
        {tabs?.map((tab) => (
          <Tab
            key={tab.value}
            label={
              tab.iconName ? (
                <Box
                  className='d-flex align-items-center d-text__font--heading color--s-dark clickable transition-fast t-color--accent'
                  sx={{display: 'flex', alignItems: 'center'}}
                >
                  {tab.isLoading ? (
                    <Spinner style={{margin: '0 0.5rem 0 0.25rem'}} />
                  ) : (
                    <span
                      className='material-symbols-outlined'
                      style={{marginRight: '0.25rem'}}
                    >
                      {tab.iconName}
                    </span>
                  )}
                  {tab.tabName}
                </Box>
              ) : (
                tab.tabName
              )
            }
            value={tab.value}
          />
        ))}
      </TabList>
    </Box>
  )
}
