import {useState} from 'react'
import {Container, Col, InputGroup, FloatingLabel, Form} from 'react-bootstrap'
import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'
import {Button} from '../../atoms/Button.js'
import {useToast} from '../../../hooks/client/useToastsClientState.js'

export const Newsletter = () => {
  const {addToast} = useToast()
  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }
  const [emailAddress, setEmailAddress] = useState('')
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
    const isValid = emailRegex.test(emailAddress)
    setEmailIsValid(isValid)

    if (!isValid) {
      event.stopPropagation()
      setIsLoading(false)
      return
    }

    let base_url = 'https://api.hsforms.com/submissions/v3/integration/submit'
    let portal_id = '144062375'
    let form_id = '44ab6eb6-148a-4041-b2b4-d1b82df81a4d'
    let request_url = `${base_url}/${portal_id}/${form_id}`
    let body = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: emailAddress
        }
      ]
    }

    await fetch(request_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(() =>
      addToast({
        header: `Subscribed Successfully!`,
        body: 'You will receive emails from us going forward.'
      })
    )

    setIsLoading(false)
  }

  return (
    <InfoPageTemplate>
      <Container
        id='newsletter'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h1 className='d-text__font--heading d-text__placement--center color--p-dark'>
            Newsletter
          </h1>
          <h3
            style={{padding: '1rem'}}
            className='d-text__font--subheading d-text__placement--center color--s-dark'
          >
            Stay up-to-date on our offering by subscribing to our newsletter below!
          </h3>

          <Form
            noValidate
            style={{width: '32rem', margin: '2rem'}}
          >
            <InputGroup>
              <FloatingLabel label='Email Address'>
                <Form.Control
                  type='email'
                  name='newsletter-email'
                  placeholder='name@example.com'
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                  required
                  isInvalid={!emailIsValid}
                />
                <Form.Control.Feedback
                  tooltip
                  type='invalid'
                >
                  Please provide a valid email address.
                </Form.Control.Feedback>
              </FloatingLabel>
              <Button
                id='button-addon1'
                children={'Subscribe'}
                onClick={(e) => handleSubmit(e)}
                isLoading={isLoading}
              />
            </InputGroup>
          </Form>
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
