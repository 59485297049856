import {Container, ToastContainer} from 'react-bootstrap'
import {useToast} from '../../hooks/client/useToastsClientState.js'
import {Toast} from '../atoms/Toast.js'

export const BaseTemplate = ({children}) => {
  const {toasts, removeToast} = useToast()

  return (
    <Container
      fluid
      className='blurred-pattern-background'
    >
      {children}
      <ToastContainer
        position='bottom-end'
        className='p-3'
        style={{zIndex: 1, position: 'fixed', bottom: 0, right: 0}}
      >
        {toasts.map((toast) => (
          <Toast
            key={toast?.id}
            show={true}
            text={toast?.body}
            header={toast?.header}
            delay={toast?.delay}
            className={toast?.className}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </ToastContainer>
    </Container>
  )
}
