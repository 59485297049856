import {Api} from './apiDefinition.js'

//Leads
export const postLeadsRank = async ({payload}) => {
  const {data} = await Api.post('/agent/leads/rank', payload)
  return data
}

//Messages
export const getSupportedLanguages = async () => {
  const {data} = await Api.get('/agent/messages/supported-languages')
  return data
}
export const getMessages = async ({params}) => {
  const {data} = await Api.get('/agent/messages', {params})
  return data
}
export const postMessage = async ({payload}) => {
  const {data} = await Api.post('/agent/messages', payload)
  return data
}
export const patchMessage = async ({params, payload}) => {
  const {data} = await Api.patch('/agent/messages', payload, {params})
  return data
}
export const deleteMessage = async ({params}) => {
  await Api.delete('/agent/messages', {params})
}
export const postMessageGenerate = async ({payload}) => {
  const {data} = await Api.post('/agent/messages/generate', payload)
  return data
}

//Message instructions
export const getMessageInstructions = async ({params}) => {
  const {data} = await Api.get('/agent/instructions/message', {params})
  return data
}
export const postMessageInstructions = async ({payload}) => {
  const {data} = await Api.post('/agent/instructions/message', payload)
  return data
}
export const patchMessageInstructions = async ({params, payload}) => {
  const {data} = await Api.patch('/agent/instructions/message', payload, {params})
  return data
}
export const deleteMessageInstructions = async ({params}) => {
  await Api.delete('/agent/instructions/message', {params})
}
