import {useState, useEffect} from 'react'
import {Container, Col} from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import {Spinner} from '../../atoms/Spinner.js'
import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'

const MarkdownContentPage = ({contentPath, pageTitle, section}) => {
  const [contentText, setContentText] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  useEffect(() => {
    fetch(contentPath)
      .then((response) => response.text())
      .then((text) => {
        setContentText(text)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(`Error fetching content from ${contentPath}:`, error)
        setIsLoading(false)
      })
  }, [contentPath])

  return (
    <InfoPageTemplate>
      <Container
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h1
            className='d-text__font--heading color--p-dark'
            style={{marginBottom: '2rem'}}
            id={section}
          >
            {pageTitle}
          </h1>

          {isLoading ? (
            <Spinner />
          ) : (
            <ReactMarkdown className='d-text__font--body d-text__placement--center color--p-dark'>
              {contentText}
            </ReactMarkdown>
          )}
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}

export const OptOutPage = () => (
  <MarkdownContentPage
    contentPath='/opt-out-text.md'
    pageTitle='Opt Out'
    section='opt-out'
  />
)

export const PrivacyPolicy = () => (
  <MarkdownContentPage
    contentPath='/privacy-policy-text.md'
    pageTitle='Pitchr Privacy Policy'
    section='privacy-policy'
  />
)

export const TermsAndConditions = () => (
  <MarkdownContentPage
    contentPath='/terms-and-conditions-text.md'
    pageTitle='Pitchr Terms and Conditions'
    section='terms-and-conditions'
  />
)
