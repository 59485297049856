import {useEffect, useState} from 'react'

import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {persistQueryClient} from '@tanstack/react-query-persist-client'

import {RecoilRoot} from 'recoil'
import {get, set, del} from 'idb-keyval'

import {initializeApp} from 'firebase/app'

import {useGetClientConfig} from '../hooks/server/useUtilitiesServerState.js'
import {Spinner} from '../components/atoms/Spinner.js'
import {ErrorProvider} from './errorContext.js'
import {RecoilResetProvider} from './recoilResetContext.js'

const persister = {
  persistClient: (client) => set('pitchrIndexDBStorage', client),
  restoreClient: () => get('pitchrIndexDBStorage'),
  removeClient: () => del('pitchrIndexDBStorage')
}

const queryClient = new QueryClient({
  defaultOptions: {queries: {gcTime: 1000 * 60 * 60 * 24 * 7}}
})

persistQueryClient({queryClient, persister})

const FirebaseInitializer = ({children}) => {
  const {data: clientConfig, isLoading, isSuccess} = useGetClientConfig()
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)

  useEffect(() => {
    if (isSuccess && clientConfig && !firebaseInitialized) {
      initializeApp(clientConfig.firebaseConfig)
      setFirebaseInitialized(true)
    }
  }, [isSuccess, clientConfig, firebaseInitialized])

  if (isLoading || !firebaseInitialized) {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <Spinner />
      </div>
    )
  }

  return children
}

export const ContextProviders = ({children}) => (
  <ErrorProvider>
    <QueryClientProvider client={queryClient}>
      <RecoilResetProvider>
        {(key) => (
          <RecoilRoot key={key}>
            <FirebaseInitializer>{children}</FirebaseInitializer>
          </RecoilRoot>
        )}
      </RecoilResetProvider>
    </QueryClientProvider>
  </ErrorProvider>
)
