//ATOMS
import {useState, memo} from 'react'
import {Container, Collapse, Dropdown} from 'react-bootstrap'
import {MessagePostModal, EmailSendModal} from './MessageModals.js'
import {Spinner} from '../atoms/Spinner.js'
import {CustomButton} from '../atoms/CustomButton.js'

const MessageContent = memo(
  ({userProfile, enrichmentPerson, generatedMessage, messageInstructionsAtom}) => (
    <>
      <div className='d-flex flex-column justify-content-start'>
        <p style={{marginBottom: 0}}>{`From: ${userProfile?.first_name}`}</p>
        <p style={{marginBottom: 0}}>{`To: ${enrichmentPerson?.first_name}`}</p>
        {generatedMessage?.header && (
          <p
            style={{marginBottom: 0}}
            className='d-flex align-items-center'
          >
            <span
              className='material-symbols-outlined color--accent'
              style={{marginRight: '0.5rem'}}
            >
              {'mail'}
            </span>
            {messageInstructionsAtom?.outreachStage === 'initial_message'
              ? ` Subject: ${generatedMessage?.header}`
              : ` Subject: Re: ${generatedMessage?.header}`}
          </p>
        )}
        <hr />
      </div>
      <div style={{width: 'inherit'}}>
        <div dangerouslySetInnerHTML={{__html: generatedMessage?.body}} />
      </div>
    </>
  )
)

export const MessageInstructionsOutput = memo(
  ({
    enrichmentPerson,
    generatedMessage,
    handlePostEmailSend,
    handlePostMessage,
    handlePostMessageGenerate,
    messageInstructionsAtom,
    postCampaignEmailsSendStatus,
    postMessageGenerateStatus,
    postMessageStatus,
    userProfile
  }) => {
    const [showInput, setShowInput] = useState(true)
    const [openSaveDialog, setOpenSaveDialog] = useState(false)
    const [openSendDialog, setOpenSendDialog] = useState(false)
    return (
      <>
        {openSaveDialog && (
          <MessagePostModal
            show={openSaveDialog}
            onHide={() => setOpenSaveDialog(!openSaveDialog)}
            handlePostMessage={handlePostMessage}
            postMessageStatus={postMessageStatus}
          />
        )}
        {openSendDialog && (
          <EmailSendModal
            show={openSendDialog}
            onHide={() => setOpenSendDialog(!openSendDialog)}
            handlePostEmailSend={handlePostEmailSend}
            postCampaignEmailsSendStatus={postCampaignEmailsSendStatus}
          />
        )}
        <Container fluid>
          <div className='d-flex justify-content-between align-items-center'>
            <h5
              className='d-text__font--heading color--p-dark d-flex align-items-center transition-fast clickable t-color--accent'
              onClick={() => setShowInput(!showInput)}
            >
              Message
              <span
                className='material-symbols-outlined'
                style={{
                  fontSize: '2rem',
                  transform: showInput ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition: 'transform 0.3s ease'
                }}
                onClick={() => setShowInput(!showInput)}
              >
                {'keyboard_arrow_down'}
              </span>
            </h5>
            <CustomButton
              onClick={() => {
                if (postMessageGenerateStatus !== 'pending') {
                  handlePostMessageGenerate()
                }
              }}
              iconName={'stylus_note'}
              label={'Create Message'}
              isLoading={postMessageGenerateStatus === 'pending'}
              style={{margin: '0 1rem 0.25rem auto'}}
            />
            <Dropdown drop='start'>
              <Dropdown.Toggle
                variant='none'
                bsPrefix='dropdownToggle'
                style={{border: 'none', padding: 0}}
                children={
                  <span
                    className='material-symbols-outlined transition-fast clickable t-scale--small t-color--accent'
                    style={{fontSize: '2rem', color: 'black'}}
                  >
                    {'more_horiz'}
                  </span>
                }
              />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setOpenSendDialog(!openSendDialog)}>
                  Send Message
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setOpenSaveDialog(!openSaveDialog)}>
                  Save Message
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <hr style={{marginTop: 0}} />
          <Collapse
            in={postMessageGenerateStatus !== 'pending' && showInput}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <MessageContent
                userProfile={userProfile}
                enrichmentPerson={enrichmentPerson}
                generatedMessage={generatedMessage}
                messageInstructionsAtom={messageInstructionsAtom}
              />
            </div>
          </Collapse>
        </Container>
      </>
    )
  }
)
