import {Component, useContext} from 'react'
import {BrowserRouter} from 'react-router-dom'

import {ContextProviders} from './contexts/contextProviders.js'
import {ErrorContext} from './contexts/errorContext.js'

import {Button, Modal} from 'react-bootstrap'
import {AppRoutes} from './components/pages/routes.js'

export const App = () => {
  return (
    <ErrorBoundary>
      <ContextProviders>
        <BrowserRouter>
          <AppRoutes />
          <ErrorMessage />
        </BrowserRouter>
      </ContextProviders>
    </ErrorBoundary>
  )
}

class ErrorBoundary extends Component {
  static contextType = ErrorContext

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
    this.context.showError(error.toString())
    console.error(error, errorInfo)
  }

  render() {
    return this.props.children
  }
}

export const ErrorMessage = () => {
  const {errorMessage, errorTitle, setErrorTitle, setErrorMessage} =
    useContext(ErrorContext)

  return (
    <Modal
      show={Boolean(errorMessage)}
      onHide={() => {
        setErrorTitle(null)
        setErrorMessage(null)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{errorTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{errorMessage}</Modal.Body>

      <Modal.Footer>
        <Button
          variant={'danger'}
          onClick={() => {
            setErrorTitle(null)
            setErrorMessage(null)
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
