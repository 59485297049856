import {createContext, useContext, useState} from 'react'

const ResetContext = createContext({
  resetRecoil: () => {}
})

export const RecoilResetProvider = ({children}) => {
  const [key, setKey] = useState(0)

  const resetRecoil = () => {
    localStorage.clear()
    sessionStorage.clear()
    setKey((prevKey) => prevKey + 1)
  }

  return (
    <ResetContext.Provider value={{resetRecoil}}>
      {children(key)}
    </ResetContext.Provider>
  )
}

export const useResetRecoil = () => useContext(ResetContext)
