//Atoms
import {useEffect, useRef, useState} from 'react'

import {
  Collapse,
  InputGroup,
  Stack,
  Modal,
  Form,
  Col,
  Row,
  FloatingLabel
} from 'react-bootstrap'

import {Button} from '../atoms/Button.js'
import {Spinner} from '../atoms/Spinner.js'

//MOLECULES
import {ActionWithConfirmation} from '../molecules/ActionWithConfirmation.js'
import {SelectCreateOptions} from '../molecules/SelectCreateOptions.js'

//Utils
import {formatDate, formatUnderscoreString} from '../../utils/helpers.js'
import {useAutosizeTextArea} from '../../utils/hooks.js'

export const MessageInstructionsModal = ({
  show,
  onHide,
  label,
  title,
  companyName,
  valueProposition,
  intention,
  preferences,
  outreachMethod,
  outreachStage,
  messageSignature,
  language,
  updatedAt,
  onDelete,
  onDeleteIsPending,
  usedInCampaign,
  onEdit
}) => {
  const valuePropositionRef = useRef(null)
  const intentionRef = useRef(null)
  const preferencesRef = useRef(null)
  const messageSignatureRef = useRef(null)
  useAutosizeTextArea(messageSignatureRef, messageSignature, show)
  useAutosizeTextArea(valuePropositionRef, valueProposition, show)
  useAutosizeTextArea(intentionRef, intention, show)
  useAutosizeTextArea(preferencesRef, preferences, show)

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      centered
    >
      <Modal.Header>
        <Stack
          direction='vertical'
          gap={0}
        >
          <h4>{label}</h4>
          <Stack
            direction='horizontal'
            gap={1}
          >
            <span className={'material-symbols-outlined color--s-dark'}>
              {'calendar_today'}
            </span>
            <small
              style={{
                fontStyle: 'italic',
                opacity: '0.7'
              }}
            >
              {formatDate(updatedAt)}
            </small>
          </Stack>
        </Stack>
        {onEdit && (
          <span
            className='material-symbols-outlined color--p-dark clickable transition-fast t-scale--small t-color--accent'
            onClick={onEdit}
          >
            {'edit_square'}
          </span>
        )}
        {usedInCampaign && (
          <span
            title='Message instructions are used in a campaign'
            style={{color: 'green'}}
            className='material-symbols-outlined'
          >
            {'campaign'}
          </span>
        )}
        {onDelete && !onDeleteIsPending && (
          <ActionWithConfirmation
            callback={() => onDelete()}
            disabled={usedInCampaign}
          />
        )}
        {onDeleteIsPending && <Spinner size='sm' />}
      </Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <Form.Group
            className='stretch-stack'
            as={Row}
          >
            <Form.Group as={Col}>
              <FloatingLabel label='Your Title'>
                <Form.Control
                  value={title}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col}>
              <FloatingLabel label='Your Company Name'>
                <Form.Control
                  value={companyName}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          </Form.Group>

          <Form.Group as={Col}>
            <FloatingLabel label='Your value proposition'>
              <Form.Control
                as='textarea'
                value={valueProposition}
                ref={valuePropositionRef}
                readOnly
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col}>
            <FloatingLabel label={"What's your intention?"}>
              <Form.Control
                as='textarea'
                value={intention}
                ref={intentionRef}
                readOnly
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col}>
            <FloatingLabel label={'What are your preferences?'}>
              <Form.Control
                as='textarea'
                value={` - ${preferences?.join('\n - ')}`}
                ref={preferencesRef}
                readOnly
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col}>
            <FloatingLabel
              label={'Language'}
              required
            >
              <Form.Control
                value={language}
                readOnly
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group
            className='stretch-stack'
            as={Row}
          >
            <Form.Group as={Col}>
              <FloatingLabel label={'Outreach Method'}>
                <Form.Control
                  value={formatUnderscoreString(outreachMethod)}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col}>
              <FloatingLabel label={'Outreach Stage'}>
                <Form.Control
                  value={formatUnderscoreString(outreachStage)}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          </Form.Group>

          <Form.Group
            className='stretch-stack'
            as={Row}
          >
            <Form.Group as={Col}>
              <FloatingLabel label={'Message Signature'}>
                <Form.Control
                  as='textarea'
                  ref={messageSignatureRef}
                  value={messageSignature}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export const SaveMessageInstructionsDialog = ({
  allInputInformationIsCorrect,
  handlePatchMessageInstructions,
  handlePostMessageInstructions,
  messageInstructions,
  messageInstructionsAtom,
  messageInstructionsFetchStatus,
  onHide,
  open,
  patchMessageInstructionsStatus,
  postMessageInstructionsStatus,
  setMessageInstructionsKey
}) => {
  const messageInstructionsOptions = messageInstructions?.map((m) => ({
    label: m?.label,
    value: m?.message_instructions_id
  }))
  const messageInstructionsExist = messageInstructions?.some(
    (messageInstruction) => messageInstruction?.label === messageInstructionsAtom?.label
  )
  const [showCreateFollowUp, setShowCreateFollowUp] = useState(false)

  useEffect(() => {
    if (
      (patchMessageInstructionsStatus === 'success' ||
        postMessageInstructionsStatus === 'success') &&
      messageInstructionsAtom?.outreachStage === 'initial_message'
    ) {
      setShowCreateFollowUp(true)
    }
  }, [
    patchMessageInstructionsStatus,
    postMessageInstructionsStatus,
    messageInstructionsAtom
  ])

  return (
    <Modal
      show={open}
      size='lg'
      onHide={onHide}
      centered
    >
      <Modal.Header>Manage Your Instructions</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <InputGroup as={Row}>
            <Col>
              <SelectCreateOptions
                options={messageInstructionsOptions}
                optionsLoading={messageInstructionsFetchStatus === 'fetching'}
                onChange={(e) => {
                  setMessageInstructionsKey('label', e?.label)
                  setMessageInstructionsKey('messageInstructionsId', e?.value)
                }}
                value={{
                  value: messageInstructionsAtom?.messageInstructionsId || '',
                  label: messageInstructionsAtom?.label
                }}
              />
            </Col>
            <Form.Group
              as={Col}
              xs='auto'
            >
              {messageInstructionsExist ? (
                <Button
                  children={'Update'}
                  onClick={handlePatchMessageInstructions}
                  disabled={!allInputInformationIsCorrect}
                  disabledText={
                    !allInputInformationIsCorrect
                      ? 'Please review your instructions'
                      : null
                  }
                  isLoading={patchMessageInstructionsStatus === 'pending'}
                />
              ) : (
                <Button
                  children={'Save'}
                  disabledText={
                    !allInputInformationIsCorrect
                      ? 'Please review your instructions'
                      : !messageInstructionsAtom?.label ||
                        messageInstructionsAtom?.label.length < 1
                      ? 'Saving your instructions requires providing a name'
                      : null
                  }
                  disabled={
                    !allInputInformationIsCorrect ||
                    !messageInstructionsAtom?.label ||
                    messageInstructionsAtom?.label?.length < 1
                  }
                  onClick={handlePostMessageInstructions}
                  isLoading={postMessageInstructionsStatus === 'pending'}
                />
              )}
            </Form.Group>
          </InputGroup>
          <Collapse in={showCreateFollowUp}>
            <p style={{textAlign: 'center'}}>
              Would you like to create instructions for a follow-up message next?
              <br />
              <span
                className='clickable transition-fast t-underline--accent color--accent'
                onClick={() => {
                  setMessageInstructionsKey('outreachStage', 'follow_up_message')
                  setMessageInstructionsKey('intention', null)
                  setMessageInstructionsKey('preferences', [])
                  onHide()
                }}
              >
                Click Here
              </span>
            </p>
          </Collapse>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export const LoadMessageInstructionsDialog = ({
  handleLoadMessageInstructions,
  messageInstructions,
  messageInstructionsFetchStatus,
  onHide,
  open
}) => {
  const [selectedMessageInstructions, setSelectedMessageInstructions] = useState(null)

  return (
    <Modal
      show={open}
      size='lg'
      onHide={onHide}
      centered
    >
      <Modal.Header>Load Instructions</Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <Form.Group as={Row}>
            <Col
              className={
                messageInstructionsFetchStatus === 'fetching'
                  ? 'd-flex justify-content-center align-items-center'
                  : 'd-flex justify-content-stretch align-items-stretch'
              }
              style={{padding: 0}}
            >
              {messageInstructionsFetchStatus === 'fetching' ? (
                <Spinner />
              ) : (
                <FloatingLabel
                  label='Select Message Instructions'
                  style={{width: '100%'}}
                >
                  <Form.Select
                    value={selectedMessageInstructions || 'default'}
                    onChange={(e) => setSelectedMessageInstructions(e?.target?.value)}
                  >
                    {messageInstructions?.map((i) => (
                      <option
                        key={i.label}
                        value={i.message_instructions_id}
                      >
                        {i.label}
                      </option>
                    ))}
                    <option
                      key={'default'}
                      value={'default'}
                    >
                      Select an option
                    </option>
                  </Form.Select>
                </FloatingLabel>
              )}
            </Col>

            <Col
              xs={'auto'}
              className='d-flex align-items-center justify-content-center'
              style={{marginLeft: '1rem'}}
            >
              <Button
                children={'Load'}
                disabled={
                  !selectedMessageInstructions ||
                  selectedMessageInstructions === 'default'
                }
                disabledText={'Please select a valid option.'}
                onClick={() => {
                  handleLoadMessageInstructions(selectedMessageInstructions)
                  onHide()
                }}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export const SignatureModal = ({
  show,
  onHide,
  setMessageInstructionsKey,
  messageInstructionsAtom
}) => {
  const [isEdit, setIsEdit] = useState(true)
  const messageSignatureRef = useRef(null)

  useAutosizeTextArea(messageSignatureRef, messageInstructionsAtom?.messageSignature)

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size='lg'
    >
      <Modal.Header className='d-flex justify-content-between align-items-center'>
        <span>Edit Your Email Signature</span>
        <Stack
          gap={1}
          direction='horizontal'
          className='d-flex align-items-center'
        >
          <p
            style={{padding: 0, margin: 0, lineHeight: 0}}
            className={`${isEdit ? '' : 'color--accent'}`}
          >
            View
          </p>
          <Form.Check
            type='switch'
            style={{marginLeft: '-0.4rem'}}
            id='edit-view-switch'
            reverse
            checked={isEdit}
            onChange={() => setIsEdit(!isEdit)}
          />
          <p
            style={{padding: 0, margin: 0, lineHeight: 0}}
            className={`${isEdit ? 'color--accent' : ''}`}
          >
            Edit
          </p>
        </Stack>
      </Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <Form.Group as={Row}>
            <InputGroup as={Col}>
              {isEdit ? (
                <Form.Control
                  style={{maxHeight: '40rem', overflowY: 'auto'}}
                  as='textarea'
                  name='messageSignature'
                  placeholder=''
                  ref={messageSignatureRef}
                  value={messageInstructionsAtom?.messageSignature || ''}
                  onChange={(e) =>
                    setMessageInstructionsKey('messageSignature', e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: messageInstructionsAtom?.messageSignature
                  }}
                />
              )}
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
